import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import TenantTopNav from './Layout/Nav/TenantTopNav';
import TenantAppAccordian from './Layout/Nav/TenantAppAccordian';
import SignInPageRoutes from './SignInPageRoutes';

function LgViewPrtLandPage({
    tenantSettings, 
    accounts, 
    notifications, 
    apiError, 
    tooltip, 
    showNav, 
    windowSize, 
    curNav, 
    firstTimeUser, 
    accessToken, 
    isAuthenticated, 
    queryRequest, 
    supportModalShown, 
    onQuery, 
    setSupportModalIsShown, 
    getNewScreenWidth, 
    resetToken,
    showNavHandler,
    onLinkClick}) {
  return (
    <Row className='content-row'>
        <Col
            className={showNav ?
                    'side-workspace-nav show'
                    : 'side-workspace-nav hide'}>
                <div className='nav-container'>
                    <TenantAppAccordian
                        tenantSlug={tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.tenantslug}
                        tenantApps={
                            tenantSettings &&
                                tenantSettings.tenant &&
                                tenantSettings.tenant.fields &&
                                tenantSettings.tenant.fields.apps
                                ? tenantSettings.tenant.fields.apps
                                : ''}
                        raiseSupport={setSupportModalIsShown}
                        sa={
                            tenantSettings &&
                                tenantSettings.tenant &&
                                tenantSettings.tenant.fields &&
                                tenantSettings.tenant.fields.sa ?
                                tenantSettings.tenant.fields.sa
                                : ''
                        }
                        tenant={tenantSettings}
                        show={showNav}
                        showNavHandler={showNavHandler}
                    screenSize={'large'}
                        onLinkClick={onLinkClick}
                    />
                </div>
        </Col>
        <Col>
            <Container fluid className={showNav ? "profiles-page body-container show" : "profiles-page body-container hide"}>
                <TenantTopNav
                    className={showNav ? 'show' : 'hide'}
                    tenant={tenantSettings && tenantSettings.tenant ? tenantSettings.tenant : null}
                    onQuery={onQuery}
                    raiseSupport={setSupportModalIsShown}
                    onWidthChange={getNewScreenWidth}
                    windowSize={windowSize}
                    tenantName={
                        tenantSettings &&
                            tenantSettings.tenant &&
                            tenantSettings.tenant.fields &&
                            tenantSettings.tenant.fields.tenantSlug ?
                            tenantSettings.tenant.fields.tenantslug : ''}
                    resetToken={resetToken}
                    showFakes={firstTimeUser}
                />
                <Col>
                    <SignInPageRoutes
                        tenantSettings={tenantSettings} 
                        accounts={accounts} 
                        notifications={notifications} 
                        apiError={apiError} 
                        tooltip={tooltip} 
                        showNav={showNav} 
                        windowSize={windowSize} 
                        curNav={curNav} 
                        firstTimeUser={firstTimeUser} 
                        accessToken={accessToken} 
                        isAuthenticated={isAuthenticated} 
                        queryRequest={queryRequest} 
                        supportModalShown={supportModalShown}
                    />
                </Col>
            </Container>
        </Col>
    </Row>
  )
}

export default LgViewPrtLandPage