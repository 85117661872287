import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import '../../../styles/layout/_layout.scss';
import { PlusCircle } from 'react-bootstrap-icons';

import SubNavEditor from './SubNavEditor';

function GroupEditor({ group, onChange, onDelete }) {
  const [expanded, setExpanded] = useState(false);
  const [maxOrderOfDisplay, setMaxOrderOfDisplay] = useState(0);
  const [sorted, setSorted] = useState(false);

  useEffect(() => {
    // Sort by order of display on load only
    if (!group || sorted || !group.subNav) {
      return;
    }
    const temp = [...group.subNav];
    temp.sort((a, b) => a.orderOfDisplay - b.orderOfDisplay);
    onChange({ ...group, subNav: temp });
    
    setSorted(true);
    // React 18 ready
    return () => {};
  }, [group, sorted, onChange])

  useEffect(()=> {
    if (!group || !group.subNav) {
      return;
    }
    const temp = [...group.subNav];
    // Now get the last order of display
    if (temp.length > 0) {
      const max = temp.slice(-1)[0].orderOfDisplay;
      setMaxOrderOfDisplay(max);
    }
    // React 18 ready
    return () => {};
  }, [group])

  function addSubNav() {
    let newGroup = { ...group }
    newGroup.subNav.push({ orderOfDisplay: maxOrderOfDisplay + 1, linkLabel: '', urlForField: '', pageItems:[] })
    onChange(newGroup)
  }

  function deleteSubNav(index){
    let newGroup = { ...group }
    newGroup.subNav.splice(index, 1);
    onChange(newGroup)
  }


  return (
    <div>
      <Row>
        <Col xs={8}>
          <input
            className='form-ctrl'
            type='text'
            required
            value={group.groupTitle}
            onChange={(e) => onChange({ ...group, groupTitle: e.target.value })}
          />
        </Col>
        <Col xs={1}>
          <Form.Check
            checked={group.show}
            onChange={() => onChange({ ...group, show: !group.show })}
          />
        </Col>
        <Col xs={1}>
          <input
            className='form-ctrl'
            type='text'
            required
            value={group.groupOrder}
            onChange={(e) => onChange({ ...group, groupOrder: e.target.value })}
          />
        </Col>
        <Col xs={1}>
          <button
            className="btn-sm-invisible-red"
            onClick={onDelete}
            aria-controls="example-collapse-text"
            aria-expanded={expanded}
          >
            Delete
          </button>
        </Col>
        <Col xs={1}>
          <button
            className="btn-sm-invisible-dark"
            onClick={() => setExpanded(!expanded)}
            aria-controls="example-collapse-text"
            aria-expanded={expanded}
          >
            ...
          </button>

        </Col>

        <Col xs={4}>
        </Col>
      </Row>
      {expanded &&
        <Card className='nav-card'>
          <Card.Body>
            <button
              className="btn-sm-invisible-dark"
              onClick={()=>{setExpanded(false)}}
              aria-controls="example-collapse-text"
            >
              {'< Back'}
            </button>
            <h3 className = 'nav-card-with-margin'>{`Nested pages within ${group.groupTitle}`}</h3>
            <div className='input-text'>Link labels are nested options within, URL for fields is the literal URL when a user clicks into a tab</div>
            <Row>
              <Col xs={3}>
                <div className='input-text'>Link Label</div>

              </Col>
              <Col xs={5}>
                <div className='input-text'>URL For Field</div>

              </Col>
              <Col xs={1}>
                <div className='input-text'>Order</div>

              </Col>
              <Col xs={2}>
              </Col>
            </Row>
            {group.subNav.map((subNav, index) => {
              return <SubNavEditor
                key={index}
                subNav={subNav}
                onChange={(subNav) => {
                  let newGroup = { ...group }
                  newGroup.subNav[index] = subNav
                  onChange(newGroup)
                }}
                onDelete={() => deleteSubNav(index)}
              />
            })}
            <Row>
              <Col xs={9}>

              </Col>
              <Col>
                <button
                  className="btn-sm-invisible-dark"
                  onClick={addSubNav}
                  aria-controls="example-collapse-text"
                >
                  <PlusCircle/>{'  '}Add Sub Nav
                </button>
              </Col>

            </Row>

          </Card.Body>
        </Card>
      }
    </div>
  )
}

export default GroupEditor;