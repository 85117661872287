import React from 'react';
import PBIEmbedClassDriven from '../PBIEmbedClassDriven';
import { models } from "powerbi-client";
import utils from '../../utils';

function CompanyAnalysisFieldDisplay({ tabInfo, fieldsToDisplay, currentlyShown }) {


    const replaceTokens = (arrayItem, fields) => {
        //first, split into array on **
        if(arrayItem.indexOf('**') > -1) {
            let thefieldItems = arrayItem.split('**');
            if(thefieldItems.length > 1){
                return utils.replaceFieldValueFromName(fields,thefieldItems[1]);
            }
        }

        return arrayItem;
    }

    const getFiltersFromAgilityObject = (aglFilterArray, fieldValuesForReplace) => {
        //the filter comes in as an array of objects, with each object having a field object and a value
        let retFilters = [];

        aglFilterArray.forEach((thisFilter) => {


            retFilters.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: thisFilter.fields.targetTable,
                    column: thisFilter.fields.targetColumn
                },
                filterType: models.FilterType.Basic,
                operator: thisFilter.fields.operator,
                values: [replaceTokens(thisFilter.fields.filterValues, fieldValuesForReplace)],
                requireSingleSelection: false
            })
        })

        return retFilters;
    }

    const getPBIOptions = (fieldsSrc, thisField) => {

        //derive the reportID field and datasetID field from the thisField object

        let repField = thisField.fieldName + 'ReportID';
        let dsField = thisField.fieldName + 'DatasetID';

        let retObj = {
            reportID: fieldsSrc[repField],
            datasetID: fieldsSrc[dsField],
            filters: fieldsSrc.filtersForPBIs ? getFiltersFromAgilityObject(fieldsSrc.filtersForPBIs, fieldsSrc) : [],
            className: 'embedded-pbi',
        }

        return retObj;
    }

    return (
        currentlyShown && fieldsToDisplay ?
            tabInfo.fields.map((thisFld) => {
                return (
                    thisFld.type === 'RTF' ?
                        <p dangerouslySetInnerHTML={{ __html: fieldsToDisplay[thisFld.fieldName] }} />
                        :
                        <PBIEmbedClassDriven
                            {...getPBIOptions(fieldsToDisplay, thisFld)} />

                )
            })
        : null
    )
}

export default CompanyAnalysisFieldDisplay