import React, { useState, useEffect} from 'react';
import { Button, Modal, Tab, Tabs, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import _ from 'lodash';

import utils from '../../../utils';
import { useToast } from '../../ToastProvider';
import CONSTANTS from "../../../constants"
import '../../../styles/layout/_layout.scss'
import ProductEditorBasic from "./ProductEditorBasic"
import ProductEdtiorDrugSubstance from "./ProductEditorDrugSubstance"
import ProductEditorAdjuvant from './ProductEditorAdjuvant';
import ProductEditorTechTransfer from "./ProductEditorTechTransfer"
import ProductEditorCharacteristics from "./ProductEditorCharacteristics"
import ProductEditorCapacity from './ProductEditorCapacity';
import checkForCapacityErrors from './checkForCapacityErrors';
import checkForBasicErrors from './checkForBasicErrors';
import checkForCharacteristicErrors from './checkForCharacteristicErrors';
import ConfirmationContent from '../ConfirmationContent';
import { ArrowRightCircle } from 'react-bootstrap-icons';

function ProductEditor({apiKey, productId, closePopup, disableProductEdits, tenantId, visibleConfidentialities, 
          editableConfidentialities, showMSTFields, userName, appClass, getFromLocalStore=false, toastClick=null}) {


  /*----------------------State Variables-----------------------------------*/
  const [product, setProduct] = useState(null);
  // Organizations used to maintain shared understanding of  organizations
  // across basic editor and supporting organizations
  function checkProduct(){
    // Print current state
    console.log(product)
    console.log(capacityDetails)
    console.log(capacityProbabilities)
 }
  const [ organizations, setOrganizations] = useState([]);
  const [ capErrors, setCapErrors ] = useState([]);
  const [ errors, setErrors] = useState([]);
  const [ capacityDetails, setCapacityDetails ] = useState([]);
  const [ capacityProbabilities, setCapacityProbabilities] = useState([]);
  const [ currentView, setCurrentView] = useState('tabs');

  const { addToast } = useToast();

  async function fetchOrganizations(){
    let organizationResult = await utils.getData('get_organizations', apiKey)
    if (typeof organizationResult !== 'string'){
      setOrganizations(organizationResult);
    } else {
      addToast({
        title: 'Failed to load organization data. Error code 1-f',
        body: organizationResult
      })
      closePopup();
    }
  }

  // Refetch data whenever a subsequent modal is closed
  async function fetchData(){
    
    if (productId === null){
      setProduct(_.cloneDeep(CONSTANTS.SHELL_PRODUCT))

    } else {
      let productResult = await utils.getData('get_product', apiKey, "?product_id="+productId)
      if (typeof productResult !== "string"){ 
        productResult.update_notes = null;
        productResult.source = null;
        setProduct(productResult);
      } else {
        addToast({
          title: 'Failed to load product. Error code 1-g',
          body: productResult
        })
        closePopup();
      }
      
      // Get capacity probability result
      let capacityProbResult = await utils.getData('get_capacity_probabilities', apiKey,
            `?product_id=${productId}&tenant_id=${tenantId}`)
      if(capacityProbResult && capacityProbResult.length>0){
        setCapacityProbabilities(capacityProbResult)
      } else{
        if (typeof capacityProbResult==='string'){
          addToast({
            title: 'Failed to load probabilities. Error code 1-h.',
            body: capacityProbResult
          })
          closePopup();
        }
      }
    } 
  }

  function fetchFromLocalStore(){
    const obj = localStorage.getItem('errorProduct');
    const objJSON = JSON.parse(obj);
    setProduct(objJSON.product);
    setCapacityDetails(objJSON.capacities)
    setCapacityProbabilities(objJSON.capacity_probabilities)
  }


  useEffect(() => {
    if (getFromLocalStore){
      fetchFromLocalStore();
    } else {
      fetchData();
    }
    fetchOrganizations();
  // eslint-disable-next-line
  },[apiKey, productId, getFromLocalStore]
  )

  function sendToLocalStorage(){
    localStorage.setItem('errorProduct', JSON.stringify({product:product, capacities: capacityDetails, capacity_probabilities:capacityProbabilities}));
  }
  // only handleSubmit
  async function handleSubmit(e){
    e.preventDefault();
    e.stopPropagation();
    product.updated_by = userName
    let result = await utils.db_api_post('upsert_product', apiKey, {product:product, capacities: capacityDetails, capacity_probabilities:capacityProbabilities})
    
    if (result.error){
      sendToLocalStorage();
      addToast({
        title: 'Failed to insert or update product--Error code 1-i',
        body: result.error,
        onClick:toastClick,
        buttonText:"Reopen product?"
      })
    }
    closePopup()
  }


  // Final Submit after Confirmation Page
  const handleSubmitWithConfirmation = async ({ updateNotes, updateSource }) => {
    let submissionDetails = {
        ...product,
        update_notes: updateNotes, 
        source: updateSource,
        updated_by: userName, 
    };
    const result = await utils.db_api_post('upsert_product', apiKey, { product: submissionDetails, capacities: capacityDetails, capacity_probabilities: capacityProbabilities });

    if (result.error){
      sendToLocalStorage();
      addToast({
        title: 'Failed to insert or update product--Error code 1-j',
        body: result.error,
        onClick:toastClick,
        buttonText:"Reopen product?"
      })
    }
    closePopup()
};




// called at the Next Step button
const preSubmit = (e) => { 
  e.preventDefault();

  let submissionErrors = [];
  const basicErrors = checkForBasicErrors(product);
  submissionErrors = submissionErrors.concat(basicErrors);
  const charErrors = checkForCharacteristicErrors(product.characteristics);
  submissionErrors = submissionErrors.concat(charErrors);
  const capacityErrors = checkForCapacityErrors(capacityDetails);

  if (submissionErrors.length > 0 || capacityErrors.length > 0) {
    setErrors(submissionErrors); 
    setCapErrors(capacityErrors);
    return;
  }  
  setCurrentView('confirmation'); // only after no errors
};

  const handleClose = () => {
    closePopup();
  }

// go back button goes from confirmation page back to the tab view
const handleGoBack = () => {
  setCurrentView('tabs');
}


// Confirmation Page Header
function renderProductDetails(product) {
  return (
    <div className='modal-title-group'>
      <Modal.Title className='body-large'><strong>Product Name: </strong><span>{product?.product_name ?? ''}</span></Modal.Title>
      <Modal.Title className='body-large'><strong>Alternative Names: </strong><span>{product?.alternative_names ?? ''}</span></Modal.Title>
      <Modal.Title className='body-large'><strong>Manufacturer: </strong> <span>{product?.manufacturer?.org_name ?? ''}</span></Modal.Title>
    </div>
  );
}




  /*-------------------------------Rendering--------------------------------*/
  return (
    <>
    <div>
      {
        product !== null ?
          <Modal dialogClassName='alc-product-modal' size='xl' show={true} className={appClass}>
            <Modal.Body className='alc-product-modal'>
            {currentView === 'tabs' && (
              <>
            <div className='modal-title-group'>
              <Modal.Title className='product-name-header'><strong>Product Name: </strong><span>{product?.product_name ?? ''}</span></Modal.Title>
              <Modal.Title className='sub-header'>Alternative Names: <span>{product?.alternative_names ?? ''}</span></Modal.Title>
              <Modal.Title className='sub-header'>Manufacturer: <span>{product?.manufacturer?.org_name ?? ''}</span></Modal.Title>
            </div>
              <Tabs>
                <Tab title="Basic Information" eventKey="home">
                  <ProductEditorBasic
                    product={product}
                    organizations={organizations}
                    setProduct={setProduct}
                    apiKey={apiKey}
                    fetchData={fetchOrganizations}
                    disableProductEdits={disableProductEdits}
                    showMSTFields = {showMSTFields}
                    checkProduct={checkProduct}
                    closePopup={closePopup}
                    toastClick={toastClick}
                    sendToLocalStorage={sendToLocalStorage}
                  />
                </Tab>
                <Tab title="Drug Substance" eventKey="drugSubstance">
                  <ProductEdtiorDrugSubstance
                    product={product}
                    setProduct={setProduct}
                    organizations={organizations}
                    apiKey={apiKey}
                    disableProductEdits={disableProductEdits}
                  />
                </Tab>
                <Tab title="Adjuvant" eventKey="adjuvant">
                  <ProductEditorAdjuvant
                    product={product}
                    setProduct={setProduct}
                    organizations={organizations}
                    apiKey={apiKey}
                    disableProductEdits={disableProductEdits}
                  />
                </Tab>
                <Tab title="Tech Transfers" eventKey="techTransfer">
                  <ProductEditorTechTransfer
                    product={product}
                    setProduct={setProduct}
                    organizations={organizations}
                    apiKey={apiKey}
                    disableProductEdits={disableProductEdits}
                  />
                </Tab>
                <Tab title="Characteristics" eventKey="characteristics">
                  <ProductEditorCharacteristics
                    product={product}
                    setProduct={setProduct}
                    apiKey={apiKey}
                    disableProductEdits={disableProductEdits}
                 />
                </Tab>
                <Tab title="Capacity" eventKey="capacity">
                  <ProductEditorCapacity
                    productCharacteristics={product.characteristics}
                    productId = {product.product_id}
                    tenantId={tenantId}
                    apiKey={apiKey}
                    capacityDetails={capacityDetails}
                    capacityProbabilities={capacityProbabilities}
                    setCapacityProbabilities={setCapacityProbabilities}
                    setCapacityDetails={setCapacityDetails}
                    visibleConfidentialities={visibleConfidentialities}
                    editableConfidentialities={editableConfidentialities}
                    closePopup={closePopup}
                  />
                </Tab>
              </Tabs>
            

              {capErrors.length > 0 ?
                <Modal.Dialog>
                <Modal.Header>
                  <Modal.Title>Would you like to override the following errors?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div>
                    <div className="error">Errors:</div>
                    {
                      capErrors.map((e, index) => <li key={index} className="error">{e}</li>)
                    }
                  </div>
                </Modal.Body>
                <div className="error">Errors:</div>
                
                
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                  <Button variant="primary" onClick={handleSubmit}>Override Errors</Button>
                </Modal.Footer>
              </Modal.Dialog>
                : null}
              {errors.length === 0? null:
                <>
                  <div className = 'error'>Errors:</div>
                  {errors.map((e, index) => <li key={index} className="error">{e}</li>)}
                </>
              }

            <ButtonToolbar className='editor-btn'>
              <ButtonGroup>
                <Button onClick={closePopup} className="button-secondary m-i-end-03">Cancel Changes</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
              <Button onClick={preSubmit} className="btn-primary">Next Step<ArrowRightCircle className='icon'/></Button>{' '}
              </ButtonGroup>
            </ButtonToolbar>
            </>
            )}
            {currentView === 'confirmation' && (
                <ConfirmationContent
                    updateNotes={product?.update_notes}
                    updateSource={product?.source}
                    onSubmit={handleSubmitWithConfirmation}
                    onGoBack={handleGoBack}
                    renderContentSection={() => renderProductDetails(product)}
                />
            )}
            </Modal.Body>
          </Modal>
          : null
      }
    </div>

    </>
  );
}  

export default ProductEditor