import React, { useState, useEffect } from 'react';
import { useToast } from '../../components/ToastProvider';
import { Badge} from 'react-bootstrap';


import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import PMTCONSTANTS from '../PMT/pmtConstants';
import DemandHealthToolbar from './DemandHealthToolbar';
import AddEditAntigenModal from './AddEditAntigenModal';
import CountryActivitiesTable from './CountryActivitiesTable';
import CheckBoxDropDownSelector from '../MktActionsFilterBarComps/CheckBoxDropDownSelector';
import utils from '../../utils';

function DemandHealthNestedTable({tenant, groupMembers, appName, apiKey, userInfo}) {
  let newDate = new Date().toISOString().split('T')
  newDate = newDate[0]

  let baseForm = {
    activity_id: null,
    activity_type: null,
    closed_date: null,
    comments: [],
    created_by: userInfo[0].displayName,
    created_date: newDate,
    deleted: false,
    details: null,
    dh_antigen: null,
    dh_country_detail: {
      ISO: null,
      dh_country_detail_id: null,
      immunization_strategy: null,
      full_portfolio_planning: null,
      deleted: false,
    },
    dh_country_detail_id: null,
    priority: null,
    source: null,
    source_updated_date: null,
    status: null,
    updated_by: null,
    updated_date: newDate,
    year: null
  }
  const [ form, setForm ] = useState(baseForm);
  const [loading, setLoading] = useState(false);
  const [ countriesData, setCountriesData ] = useState();
  const [ activityUpdated, setActivityUpdated ] = useState(false);
  const [ updatedActivity, setUpdatedActivity ] = useState({});
  const [ itemISO, setItemISO ] = useState(null);
  const [ comments, setComments ] = useState([]);
  const [ show, setShow ] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedAntigens, setSelectedAntigens] = useState([]);
  const [antigenList, setAntigenList] = useState([])
  
  const { addToast } = useToast();



  const getAntigenList = async (key) => {
    // Fetch the enum data from the API if no pre fetchedEnums are passed
    let enumResult = await utils.getData('get_enums', key, `?enum_type=antigen_dh&include_deleted=True`);
    if (typeof enumResult === 'string') {
      addToast({
        title: 'Failed to load enum data. Error code 2-a',
        body: enumResult,
      });
      return;
    }
    let retAntigenList = enumResult.filter((en) => en.deleted === false)
    retAntigenList = retAntigenList.map((ant) => ant.enum)
    setAntigenList(retAntigenList)  
  }
  
  const getPMTList = async (apiKey, app, inclDel = false) => {

      let url = PMTCONSTANTS[app.fields.apptype].GET_ACTIVITIES;
      const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${url}?include_deleted=${inclDel}`, {
        headers: {
          'Content-Type': 'application/json',
          'access_token': apiKey
        }
      })
      if (result['status'] === 403) {
        alert("Invalid API key, access denied")
      }
  
      let resJson = await result.json()
      let cntrsList = await resJson.map((cntry) => cntry.country)
      setCountriesList(cntrsList);
      setCountriesData(resJson);
    }

    const postActivityUpdate = async (updateObj, location=null) => {

      updateObj.updated_date = newDate;
      let params = updateObj && updateObj.activity_id ? `?activity_id=${updateObj.activity_id}` : ''
      
      const result = await fetch(`${process.env.REACT_APP_DB_API_URL}upsert_demand_health_activity${params}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'access_token': apiKey
          //need to add other access token for workspace access here
        },
        body: JSON.stringify(updateObj)
      })

      switch (result['status']) {
        case 403:
          addToast({
            title: 'isValid Key',
            body: "Invalid API key, access denied"
          })
          break;
        case 422:
          addToast({
            title: 'isValid Input',
            body: "Invalid Input, check input type"
          })
          break;
        case 200:
          if ( location === 'popup') {
            setUpdatedActivity(updateObj)
            handleClose()
          }
          break;
        default:
          addToast({
            title: 'Error',
            body: "Something went wrong, please try again."
          })
      }
    }
    
    //update the backend with the activity
    useEffect(() => {
      if(activityUpdated) {
        //post the update
        postActivityUpdate(updatedActivity);
        setActivityUpdated(false);
      }
      //eslint-disable-next-line
    }, [ activityUpdated ]);

    useEffect(() => {
      try {
        (async () => {
          
          if(appName && apiKey) {
            setLoading(true)
            await getPMTList(apiKey, appName, false);
            await getAntigenList(apiKey)
            setLoading(false)
          }           
        })()
      } catch (err) {
        addToast({
          title: 'Update Error',
          body: 'Whoops!  something happened.  Please try again.'
        })
      }
      //eslint-disable-next-line 
    }, [appName, apiKey])


const handleShowListItemModal = (id = null) => {
  if (id) {
      setItemISO(id)
  }
  if (show === true) {
      setItemISO(null);
  }
  setShow(!show);
}

const handleClose = (iso=null) => {
  setItemISO(null)
  setForm(baseForm);
  setShow(false);

  if(iso) {
    setUpdatedActivity({
      dh_antigen: 'placeholder',
      dh_country_detail: {
        ISO: iso
      }
    })
  }
}


const textareas = document.querySelectorAll('textarea');
const specificCell = document.querySelectorAll('.doubleClick');

specificCell.forEach(cell => {
  cell.addEventListener('dblclick', function() {
    setShow(true);
  })
})

textareas.forEach(textbox => {
    textbox.addEventListener('dblclick', function() {
        setShow(true)
    });
});

const getCountryDetails= (country, list) => {
  let ISOVal = list.filter(item => {
    return item.country === country;
  })

  if( ISOVal.length > 0) {
    return ISOVal[0].nestedData[0].dh_country_detail;
  }
}

  //on field change other than comments updates form
  const onChange = (field, value) => {
    let copy = {...form}
    switch (field) {
      case 'dh_antigen':
        copy.dh_antigen = value && value.enum ? value.enum : '';
        setForm(copy)
        break;
      case 'country':
        let details = getCountryDetails(value, countriesData)
        copy.dh_country_detail = details ? details : ''
        copy.dh_country_detail_id = details.dh_country_detail_id ? details.dh_country_detail_id : null;
        setForm(copy)
        break;
      case 'activity_type':
        setForm({
          ...form,
            activity_type: value ? value.enum : ''
        })
        break;
      case 'year':
        setForm({
          ...form,
          year: value ? value : null
        })
        break;
      default:
        setForm({
          ...form,
          [field]: value === 'none' ? '' : value
        });
    }    
  };

  const getAllCountries = (list) => {
    let isoObj = {}
    let countryObj = {}
    let countryList = list.map((item) => {
      isoObj[item.ISO] = item.country
      return item.country;
    })
    countryObj.countryName = countryList;
    countryObj.countryObj = isoObj
    return countryObj;
  }
  
  const addFormData = (itemObj) => {
    setItemISO(itemObj.dh_country_detail.ISO)
    setForm({
      activity_id: itemObj.activity_id ? itemObj.activity_id : null,
      activity_type: itemObj.activity_type ? itemObj.activity_type : null,
      closed_date: itemObj.closed_date ? itemObj.closed_date : null, 
      comments: itemObj.comments ? itemObj.comments : [],
      created_by: itemObj.created_by ? itemObj.created_by : userInfo[0].displayName,
      created_date: itemObj.created_date ? itemObj.created_date : newDate,
      deleted: itemObj.deleted ? itemObj.deleted : false,
      details: itemObj.details ? itemObj.details : null,
      dh_antigen: itemObj.dh_antigen ? itemObj.dh_antigen : null,
      dh_country_detail: {
        ISO: itemObj.dh_country_detail.ISO ? itemObj.dh_country_detail.ISO : null,
        deleted: itemObj.dh_country_detail.deleted ? itemObj.dh_country_detail.deleted : false,
        dh_country_detail_id: itemObj.dh_country_detail.dh_country_detail_id ? itemObj.dh_country_detail.dh_country_detail_id : null,
        full_portfolio_planning: itemObj.dh_country_detail.full_portfolio_planning ?  itemObj.dh_country_detail.full_portfolio_planning : null,
        immunization_strategy:  itemObj.dh_country_detail.immunization_strategy ?  itemObj.dh_country_detail.immunization_strategy : null,
      },
      dh_country_detail_id:  itemObj.dh_country_detail_id ?  itemObj.dh_country_detail_id : null,
      priority: itemObj.priority ? itemObj.priority : null,
      source: itemObj.source ? itemObj.source : null,
      source_updated_date: itemObj.source_updated_date ? itemObj.source_updated_date : null,
      status: itemObj.status ? itemObj.status : null,
      updated_by: itemObj.updated_by ? itemObj.updateObj : null,
      updated_date: itemObj.updated_date ? itemObj.updated_date : null,
      year: itemObj.year ? itemObj.year : null
    })    
  }
    //updates a comment in state
    const updateListWithComment = (commentObj) => {
    let copyComList = [...comments];

    //checks if there is a comment obj without a commentID
    if (copyComList.some(comment => !comment.comment_id)) {
      // if obj exists it updates the comment
      copyComList = comments.map((comment) => {
        if(!comment.comment_id) {
          comment = commentObj;
          return comment;
        }
        return comment;
      })
      //if it doesn't exists it pushes the new comment obj into the comments state array
    } else {
      copyComList.push(commentObj)
    }
    // //set new comment obj in state
    setComments(copyComList);
  }

  const clearCommentInput = () => {
    document.getElementById('comment_value').value = null
  } 

  const submitListItemComment = () => {
    let formCopy = {...form}
    formCopy.comments = comments;
    formCopy.updated_by = userInfo[0].displayName
    setForm(formCopy)
    postActivityUpdate(formCopy);
    clearCommentInput();
  }

    const getLabelString = (list, listName) => {

      if (list.length > 1) {
          return 'Multiple selected';
      }
  
      if (list.length === Object.keys(countriesList).length) {
        return `All ${listName} selected`;
      }
    
      if (list.length === 1) {
        return list[0];
      }

      return ' ...';
    };

  // Function for country selection
  const handleSelectedCountryItems = (val, selected, fullList) => {
    let updatedSelection;

    if (val.target.defaultValue === 'select-all') {
      updatedSelection = selected.length === fullList.length ? [] : fullList;
    } else {
      updatedSelection = selected.includes(val.target.defaultValue)
        ? selected.filter(item => item !== val.target.defaultValue)
        : [...selected, val.target.defaultValue];
    }

    setSelectedCountries(updatedSelection);
  };

  const handleSelectedAntigenItems = (val, selItems, fullList) => {
    let updatedSelection;
    let selected = [...selItems];

    if (val.target.defaultValue === 'select-all') {
      updatedSelection = selected.length === fullList.length ? [] : fullList;
    } else {
      if (selected.includes(val.target.defaultValue)) {
        updatedSelection = selected.filter(item => item !== val.target.defaultValue);
      } else {

        updatedSelection = [...selected, val.target.defaultValue];
      }
    }

    setSelectedAntigens(updatedSelection);
  };

  const filterToCountriesSelected = (dataList, selCountries) => {
   
    if (selCountries && selCountries.length > 0) {
      let filteredCntryList = dataList.filter((country) => selCountries.includes(country.country))
      return filteredCntryList;
    } else {
      return dataList;
    }
  }

  return (
    !loading && countriesData ?
    <>
      <h3 className='h3 m-b-start-06'>Demand health by country</h3>
      <p className='label-medium m-b-end-08'>Priority and progress by country across antigens (2023-2025).</p>
      <p className='body-small-condensed p-b-start-06 m-b-end'>Changes made in the below table will be reflected in the dashboard visuals following a database refresh made twice-daily.</p>
      
      <DemandHealthToolbar 
        appTab={'antigen'}
        handleShowListItemModal={handleShowListItemModal} 
      />

      <div className='m-b-end-12 overflow-x-scroll'>

        <table className='full-width m-b-end-08 demand-health-activities-table'>
            <thead>
                <tr className='border-bottom-light'>
                  <th className='body-small px-width-175 p-i-start-05 font-weight-bold surface-primary-background'>
                    <span>Country</span>
                    <CheckBoxDropDownSelector
                      className="table-dropdown-ghost"
                      type="dropdown"
                      options={utils.getOptionsObj(countriesList, selectedCountries)}
                      keepOpen={true}
                      displayOptionHandler={(e) => handleSelectedCountryItems(e, selectedCountries, countriesList)}
                      labelString={getLabelString(selectedCountries, 'Countries')}
                      isSearchable={false}
                      selected={utils.getOptionsObj(selectedCountries, selectedCountries)}  // Convert selected into object form
                      selectAll={true}
                    />
                  </th>
                  <th className='body-small px-width-100 p-i-start font-weight-bold m-b-end-02 surface-primary-background'></th>
                  <th className='body-small px-width-225 p-i-start font-weight-bold m-b-end-02 surface-primary-background'>Financing Status</th>
                  <th className='body-small px-width-150 p-i-start font-weight-bold surface-primary-background border-right'>2023 Birth Cohort</th>
                  <th className='body-small px-width-250 p-i-start-05 font-weight-bold surface-primary-background'>
                    <span>Antigen</span>
                    <CheckBoxDropDownSelector
                      className="table-dropdown-ghost"
                      type="dropdown"
                      options={utils.getOptionsObj(antigenList, selectedAntigens)}
                      keepOpen={true}
                      displayOptionHandler={(e) => handleSelectedAntigenItems(e, selectedAntigens, antigenList)}
                      labelString={getLabelString(selectedAntigens, 'Antigens')}
                      isSearchable={false}
                      selected={utils.getOptionsObj(selectedAntigens, selectedAntigens)}  // Convert selected into object form
                      selectAll={true}
                    />
                  </th>
                  <th className='body-small px-width-100 p-i-start font-weight-bold surface-primary-background'>Priority</th>
                  <th className='body-small px-width-225 p-i-start font-weight-bold surface-primary-background'>Progress</th>
                  <th className='body-small px-width-250 p-i-start font-weight-bold surface-primary-background'>Details</th>
                  <th className='body-small px-width-100 p-i-start-06 font-weight-bold surface-primary-background'>Activity Year</th>
                  <th className='body-small px-width-225 p-i-start-06 font-weight-bold surface-primary-background'>Source</th>
                  <th className='body-small px-width-225 p-i-start-06 font-weight-bold surface-primary-background'>Activity Type</th>
                  <th className='body-small px-width-100 p-i-start font-weight-bold surface-primary-background'>Update Date</th>
                  <th className='body-small px-width-250 p-i-start font-weight-bold surface-primary-background'>Comments</th>
                </tr>
            </thead>
            <tbody>
              {
                filterToCountriesSelected(countriesData, selectedCountries).map((row) => {  
                  return (
                    <tr>
                      <td className='px-width-175 body-med vertical-align-top p-i-start-05 p-b-start-03'>{row.country}</td>
                      <td className='px-width-100 body-med vertical-align-top p-b-start-03'>
                        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY['who region']} txt-primary label-small border-radius-lg p-i-04 p-b-02`}>
                          {row.who_region}
                        </Badge>
                      </td>
                      <td className='px-width-225 body-med vertical-align-top p-b-start-03'>{row.gavi_cofinancing}</td>
                      <td className='px-width-150 body-med vertical-align-top p-b-start-03 border-right'>{row.birth_cohort.toLocaleString()}</td>
                      <td colSpan={9}>
                        
                        <CountryActivitiesTable
                          iso={row.ISO}
                          groupMembers={groupMembers}
                          tenant={tenant}
                          appName={appName} 
                          addFormData={addFormData} 
                          apiKey={apiKey}
                          inclDel={false}
                          postActivityUpdate={postActivityUpdate}
                          updatedActivity={updatedActivity}
                          appliedFilters={selectedAntigens}
                        />
         
                      </td>
                  </tr>
                  )
                })
              }
            </tbody>
        </table>
      </div>

      {
        show === true ?
          <AddEditAntigenModal 
            itemISO={itemISO} 
            data={countriesData}
            show={show} 
            handleClose={handleClose}
            form={form}
            onChange={onChange}
            countries={getAllCountries(countriesData)}
            postActivityUpdate={postActivityUpdate}
            userInfo={userInfo}
            apiKey={apiKey}
            updateListWithComment={updateListWithComment}
            submitListItemComment={submitListItemComment}
            clearCommentInput={clearCommentInput}
          />
        : ''
      }
    </>
    : <ListSkeletonLoader preview={false} />
  )
}

export default DemandHealthNestedTable;