import React, { useState, useEffect } from 'react';

import { Table, Row, Col, Button } from 'react-bootstrap'

import PageItemModal from './PageItemModal';
import '../../../styles/layout/_layout.scss'

function PageItemEditor({ pageItems, onChange, subNavName }) {
  const [pageItem, setPageItem] = useState(null);
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [sorted, setSorted] = useState(false);
  const [maxDisplayOrder, setMaxDisplayOrder] = useState(0);

  useEffect(()=> {
    if (!pageItems) {
      return;
    }
    const temp = [...pageItems];
    // Now get the last order of display
    if (temp.length > 0) {
      const max = temp.slice(-1)[0].orderDisplayed;
      setMaxDisplayOrder(max);
    } else {
      setMaxDisplayOrder(0);
    }
    // React 18 ready
    return () => {};
  }, [pageItems])

  useEffect(() => {
    if (!pageItems || sorted) {
      return;
    }
    const temp = [...pageItems];
    temp.sort((a, b) => a.orderDisplayed - b.orderDisplayed);
    onChange(temp);
    setSorted(true);
  },[pageItems, sorted, onChange])

  function handlePageItemChange(pi) {
    const temp = [...pageItems];
    temp[index] = pi;
    onChange(temp);
    setPageItem(pi);
  }

  function handleRowClick(idx) {
    setIndex(idx);
    setPageItem(pageItems[idx]);
    setShow(true);
  }

  function addPageItem() {
    const temp = [...pageItems];
    temp.push({ orderDisplayed: maxDisplayOrder + 1, securityLevel:'3', overrides: [], pBIFilter: [] });
    onChange(temp);
  }
  function deletePageItem(idx) {
    const temp = [...pageItems];
    temp.splice(idx, 1);
    onChange(temp);
  }


  return (
    <div>
      <h3 className = 'nav-card-with-margin'>{`Page Items for Sub Nav ${subNavName}`}</h3>
      <Row>
        <Col xs={10}/>
        <Col>
          <Button className='button-secondary m-i-end-02' onClick={addPageItem} variant="secondary">
            Add Page Item
          </Button>
        </Col>
      </Row>
      
      <Table striped size="sm">
        <thead className="table-heading">
          <tr>
            <th>Title</th>
            <th>Field Name</th>
            <th>Field Type</th>
           
            <th>Order</th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          {pageItems.map((pageItem, index) => {
            return <tr key={index} onDoubleClick={() => handleRowClick(index)}>
              <td><div>{pageItem.itemTitle}</div></td>
              <td>{pageItem.fieldAPIName}</td>
              <td>{pageItem.fieldType}</td>
              <td>{pageItem.orderDisplayed}</td>
              <td><button className="btn-sm-invisible-red" onClick={() => deletePageItem(index)}>Delete</button></td>
            </tr>
          })

          }
        </tbody>
      </Table>
      {
        show&&
        <PageItemModal 
          pageItem={pageItem} 
          onChange={handlePageItemChange}
          onDelete={() => {
            deletePageItem(index);
            setShow(false);
            }  
          }
          close={() => setShow(false)}
        />
          
      }

    </div>
    
  )
}

export default PageItemEditor;