import React, { useState, useEffect } from 'react';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import utils from '../../utils';

function PublicationsList({ listName, titleOfList}) {

    let [ loading, setLoading ] = useState(true);
    let [ pubList, setPubList ] = useState([]);

    const sortByRecentDate = (a, b) => {
        if(a.fields.dateOfPublication && b.fields.dateOfPublication) {
            return new Date(b.fields.dateOfPublication) - new Date(a.fields.dateOfPublication);
        } else {
            return 0;
        }
    }

    const loadPubs = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?contenttype=${listName}`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        if(jsonresponse.items) {
            jsonresponse.items.sort(sortByRecentDate);
            setPubList(jsonresponse.items);
        }
    }

    useEffect( () => {
        try {
            setLoading(true);
            loadPubs();
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <h1>{ titleOfList }</h1>
                </Col>
            </Row>
            {
                loading ?
                    <Spinner animation='border' className="text-center"/>
                :
                    pubList.map( (thisPub, index) => {
                        return (
                            
                            thisPub.fields.linkToFile && thisPub.fields.linkToFile.url ? 
                                <a href={thisPub.fields.linkToFile.url} target="_blank" rel="noreferrer">
                                    <Row key={index} className='extra-spacer'>
                                        <Col xs={12} lg={2} style={{paddingTop: '12px'}}>
                                            {
                                                thisPub.fields.coverImage && thisPub.fields.coverImage.url ?
                                                    <img src={thisPub.fields.coverImage.url} alt={thisPub.fields.title} className="img-fluid" />
                                                :
                                                null
                                            }
                                        </Col>
                                        <Col xs={12} lg={10}>
                                            <Row>
                                                <Col xs={12} lg={12}><h3>{ thisPub.fields.title }</h3></Col>
                                                
                                                <Col className='h3' xs={12} lg={9}>{thisPub.fields.labelofPublication ? thisPub.fields.labelofPublication : ''}</Col>
                                                <Col className='h3 align-right' xs={12} lg={3}>{ thisPub.fields.dateOfPublication ? utils.getDateString(thisPub.fields.dateOfPublication, 'long', 'without-day') : '' }</Col>
                                                <Col className='body-medium-condensed text-primary-light' xs={12}>
                                                    <div className="smallText" dangerouslySetInnerHTML={{__html: thisPub.fields.descriptionofPublication }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </a>
                            :
                            <Row key={index} className='extra-spacer'>
                                <Col xs={12} lg={2} style={{paddingTop: '12px'}}>
                                    {
                                        thisPub.fields.coverImage && thisPub.fields.coverImage.url ?
                                            <img src={thisPub.fields.coverImage.url} alt={thisPub.fields.title} className="img-fluid" />
                                        :
                                        null
                                    }
                                </Col>
                                <Col xs={12} lg={10}>
                                    <Row>
                                        <Col xs={12} lg={12}><h3>{ thisPub.fields.title }</h3></Col>
                                        
                                        <Col xs={12} lg={10}><strong>{thisPub.fields.labelofPublication ? thisPub.fields.labelofPublication : ''}</strong></Col>
                                        <Col xs={12} lg={2}><strong>{ thisPub.fields.dateOfPublication ? new Date(thisPub.fields.dateOfPublication).toLocaleDateString() : '' }</strong></Col>
                                        <Col xs={12}>
                                            <div className="smallText" dangerouslySetInnerHTML={{__html: thisPub.fields.descriptionofPublication }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })
            }
        </Container>
    )
}

export default PublicationsList;