import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'

import StatefulSearchTable from '../StatefulSearchTable';
import AppEditor from './AppEditor'
import {useToast} from '../../ToastProvider'
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';

const metaData=[
  {title:'App Type', dispField:'appType', filterField:'appType'}
]

const sortData=[
  {title:"App Type", field:'appType'}
]

async function fetchData(setApps, setAppTypes, setAppTypeToApp, apiKey, addToast) {
  setApps([])
  setAppTypes([])
  // Holds the options for the app types--forcing the user to select from a list of options
  let result = await utils.getData("get_app_types", apiKey)
  if (typeof result === 'string'){
    addToast({
      title: 'Failed to load data. Error code 5-e',
      body: result
    })
  } else {
    setAppTypes(result);
  }
  // Dictionary to go from app type to app
  let appTypeToApp = {}
  for (let app of result) {
    appTypeToApp[app.value] = app.label
  }
  setAppTypeToApp(appTypeToApp);
  result = await utils.getData("get_apps", apiKey)
  if (typeof result === 'string'){
    addToast({
      title: 'Failed to load data. Error code 5-d',
      body: result
    })
  } else {
    result = result.map(app => {
      return {...app, appType: appTypeToApp[app.key]}
    })
    setApps(result);
  }
}

function AppSearch(props) {
  // Local representation with data augmentations
  const [apps, setApps] = useState([]);
  // Holds the types of apps for the tables
  const [appTypes, setAppTypes] = useState([]);
  const [appTypeToApp, setAppTypeToApp] = useState({});
  const [unusedApps, setUnusedApps] = useState([]);
  // Product that gets edited when double clicked
  const [appId, setAppId] = useState(null)
  const [appType, setAppType] = useState(null);
  // A toggle for showing the edit product modal
  const [showEditApp, setShowEditApp] = useState(false)
  // Automatic trigger to refetch the data whenever the user is done editing the data.
  // This is automatically triggered by switching the t/f variable for showing the editing modal to false
  
  const {addToast} = useToast();
  const [getFromLocalStore, setGetFromLocalStore] = useState(false);

  
  // only fetch data if 'Nav' tab is selected and editor is closed
  useEffect(() => {
    if (props.editor !== 'Nav' || showEditApp) return;
    fetchData(setApps, setAppTypes, setAppTypeToApp, props.apiKey, addToast);
  }, [props.editor, showEditApp, props.apiKey, addToast]);
  
  useEffect(() => {
    const usedApps = apps.map((a) => {return a.key});
    const unused = appTypes.filter((a) => !usedApps.includes(a.value));
    setUnusedApps(unused);
  },[appTypes, apps])


  function toggleShowEditApp() {
    setShowEditApp(!showEditApp)
  };

  function createNew() {
    toggleShowEditApp()
    setAppId(null);
    setAppType(null);
    setGetFromLocalStore(false);
  }

  function update(app) {
    toggleShowEditApp()
    setAppId(app.app_id)
    setAppType(appTypeToApp[app.key])
    setGetFromLocalStore(false);
  }

  function openLocalStoreApp(){
    setShowEditApp(true);
    setAppId(null);
    setGetFromLocalStore(true);
  }

  // Passed into search table to add buttons to the left of the required buttons
  const toolbarButtons = () => {
    return (
      <>
        <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
      </>
    )
  }

  return (
    <div>
      {showEditApp ?
        <AppEditor 
          id={appId}
          initialAppType={appType}
          handleSubmit={toggleShowEditApp}
          closePopup={toggleShowEditApp}
          apiKey={props.apiKey}
          appClass={props.appClass}
          getFromLocalStore={getFromLocalStore}
          toastClick={openLocalStoreApp}
          appTypes={unusedApps}
          appTypeToApp={appTypeToApp}
        />
        : null
      }
      <StatefulSearchTable
        data={apps}
        metaData={metaData}
        sortData={sortData}
        onRowClick={update}
        apiKey={props.apiKey}
        editor={props.editor}
        module='app'
        rowsPerPage={20}
        toolbarButtons={toolbarButtons}
        appClass={props.appClass}
      />
    </div>
  )
}

export default AppSearch;