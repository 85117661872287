import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap'

import PBIFilterEditor from './PBIFilterEditor';
import SimpleSelect from '../SimpleSelect';

// This COULD be an enum selector, but I don't think it's worth the overhead to make all the API calls.
const operatorOptions = ['equals', 'does not equal', 'contains', 'just on Tenant']

function OverrideModal({override, onChange, close, pageItemName }) {
  return (
    <div>
      {
        override ?
          <Modal show={true} size="lg" className={'alc-app'}>
            <Row>
              <Col>
                <button
                  className="btn-sm-invisible-dark"
                  onClick={close}
                  aria-controls="example-collapse-text"
                >
                  {'< Back'}
                </button>
              </Col>
            </Row>
            
            <Modal.Header>
              <Modal.Title>Overrides for {pageItemName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <div className='input-text'>Condition Field</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.conditionFieldName}
                    onChange={(e)=> onChange({ ...override, conditionFieldName: e.target.value })} 
                  />
                </Col>
                <Col>
                  <div className='input-text'>Condition Operator</div>
                  <SimpleSelect
                    options={operatorOptions}
                    value={override.conditionOperator}
                    onChange={(e)=> onChange({ ...override, conditionOperator: e})}
                  />
                </Col>
                <Col>
                  <div className='input-text'>Condition Value</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.conditionValue}
                    onChange={(e)=> onChange({ ...override, conditionValue: e.target.value })} 
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='input-text'>Content ID</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.pyramidContentIDtoUse}
                    onChange={(e)=> onChange({ ...override, pyramidContentIDtoUse: e.target.value })} 
                  />
                </Col>
                <Col>
                  <div className='input-text'>Filter String</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.filterString}
                    onChange={(e)=> onChange({ ...override, filterString: e.target.value })} 
                  />
                </Col>
                <Col>
                  <div className='input-text'>Height in Px</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.customPyramidHeightinPixels}
                    onChange={(e)=> onChange({ ...override, customPyramidHeightinPixels: e.target.value })} 
                  />
                </Col>
              </Row>
              <Row>
              <Col>
                  <div className='input-text'>Dataset ID</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.pBIDatasetID}
                    onChange={(e)=> onChange({ ...override, pBIDatasetID: e.target.value })} 
                  />
                </Col>
                <Col>
                  <div className='input-text'>Report ID</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.pBIReportID}
                    onChange={(e)=> onChange({ ...override, pBIReportID: e.target.value })} 
                  />
                </Col>
                <Col>
                  <div className='input-text'>Width in %</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.customPyramidWidthinPercent}
                    onChange={(e)=> onChange({ ...override, customPyramidWidthinPercent: e.target.value })} 
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='input-text'>Viz Filters</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={override.vizFilters}
                    onChange={(e)=> onChange({ ...override, vizFilters: e.target.value })} 
                  />
                </Col>
              </Row>
              <div className='nav-card-with-margin'/>
              <PBIFilterEditor
                pBIFilter={override.pBI_Override_Filters}
                onChange={(pBIFilter) => onChange({ ...override, pBI_Override_Filters: pBIFilter })}
              /> 
            </Modal.Body>
          </Modal>
          : null
      }
    </div>
  )
}

export default OverrideModal;