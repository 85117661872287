import React, {useEffect, useState} from 'react';
import { Button, ButtonToolbar, ButtonGroup, Row, Col, Modal, Table } from 'react-bootstrap'
import CONSTANTS from '../../../constants';
import _ from "lodash" // For making deep copies of objects
import utils from '../../../utils';
import EnumSelector from '../EnumSelector';


function CountryEditor(props) {
  const [ country, setCountry ] = useState();
  const [ countryInfo, setCountryInfo ] = useState();
  const [errors, setErrors] = useState([])


  useEffect(()=>{
    async function getData(){
      if (props.id){
        let countryResult = await utils.getData('get_country', props.apiKey, '?ISO='+props.id)
        let countryInfoResult = await utils.getData('get_country_info', props.apiKey, '?ISO='+props.id)
        if (countryResult){
          setCountry(countryResult)
        }
        if (countryInfoResult){
          setCountryInfo(countryInfoResult)
        } else {
          setCountryInfo(_.cloneDeep(CONSTANTS.SHELL_COUNTRY_INFO))
        }
      } else {
        setCountry(_.cloneDeep(CONSTANTS.SHELL_COUNTRY))
        setCountryInfo(_.cloneDeep(CONSTANTS.SHELL_COUNTRY_INFO))
      }
    }
    getData()
  }, [props.apiKey, props.id])

  async function handleSubmit (e){
    e.preventDefault();
    e.stopPropagation();

    let foundNames = new Set()
    let submissionErrors = [];

    for (let i = 0; i< country.alt_names.length; i++){

      if (foundNames.has(country.alt_names[i].alt_country_name.toLowerCase())){
        submissionErrors.push(country.alt_names[i].alt_country_name + " appears twice")
      } else {
        foundNames.add(country.alt_names[i].alt_country_name.toLowerCase())
      }
    }

    setErrors(submissionErrors)
    if (submissionErrors.length > 0)  {
      return
    }

    const data = country;
    var result
    e.preventDefault();
    e.stopPropagation();
    result = await utils.db_api_post('upsert_country', props.apiKey, data)
    if (result.error) {
      window.alert('Error: '+ result.error)
    } else {
      props.closePopup()
    }
  }

  function printCountry() {
    console.log(country)
  }

  function addSynonym() {
    const tempAltNames = country.alt_names
    tempAltNames.push({alt_country_name: ''})
    setCountry({...country, alt_names:tempAltNames})
  }

  function setSynonym(synonym, index){
    const synonyms = [...country.alt_names]
    synonyms[index].alt_country_name = synonym
    setCountry({...country, alt_names: synonyms})
  }

  function deleteSynonym(index) {
    const tempAltNames = [...country.alt_names]
    tempAltNames.splice(index, 1)
    setCountry({...country, alt_names: tempAltNames})
  }

  return (
    <div>
      {country && countryInfo ?
        <Modal size='lg' show={true} className={'alc-app'}>
          <Modal.Header>
            <Modal.Title>Country Editor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <Row>
              <Col>
                <div className='input-text'>Country Name</div>
                <input type='text'
                  className='form-ctrl'
                  value={country.country}
                  onChange={(e) => (e) = setCountry({ ...country, country: e.target.value })}
                ></input>
                <div className='input-text'>WHO Region</div>
                <EnumSelector
                  className="select"
                  enum_type="who_region"
                  apiKey={props.apiKey}
                  value={country.WHO_region}
                  clearAfterSelect={true}
                  onChange={(e) => setCountry({ ...country, WHO_region: e.label})} />
              </Col>
              <Col>
                <div className='input-text'>ISO</div>
                <input type='text'
                  className='form-ctrl'
                  value={country.ISO}
                  onChange={(e) => (e) = setCountry({ ...country, ISO: e.target.value })}
                ></input>
                <div className='input-text'>UNICEF Region</div>
                <EnumSelector
                className="select"
                enum_type="unicef_region"
                apiKey={props.apiKey}
                value={country.UNICEF_region}
                clearAfterSelect={true}
                onChange={(e) => setCountry({ ...country, UNICEF_region: e.label})} />

              </Col>
            </Row>
            <Row>
              <Col>
                <div className='input-text'> <strong>WB Status:</strong></div>
                <div>{countryInfo.wb_status}</div>
              </Col>
              <Col>
                <div className='input-text'><strong>GNI Per Capita:</strong></div>
                <div>{countryInfo.gni_per_capita}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='input-text'><strong>GDP Per Capita:</strong></div>
                <div>{countryInfo.gdp_per_capita}</div>
              </Col>
              <Col>
                <div className='input-text'><strong>GAVI Cofinancing</strong></div>
                <div>{countryInfo.gavi_cofinancing}</div>
              </Col>
            </Row>
            
            <Table>
              <thead className='table-heading'>
                <tr>
                  <th>Synonym</th>
                  <th>
                    <div className='clickable-text' onClick={addSynonym} size="sm">add</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {country.alt_names.map((item, i) => {
                  return <tr key={i}>
                    <td>
                      <input type='text'
                        className ='form-ctrl'
                        value={item.alt_country_name}
                        onChange={(e) => setSynonym(e.target.value, i)}
                      ></input>
                    </td>
                    <td className='right-align'>
                      <Button variant="danger" size="sm"
                        onClick={() => deleteSynonym(i)}>-</Button>
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
            {errors.length > 0 ?
              <div>
                <div className="error">Errors:</div>
                {
                  errors.map((e) => <li className="error">{e}</li>)
                }
              </div>
              : null}
            <ButtonToolbar className='editor-btn'>
              <ButtonGroup>
                <Button onClick={props.closePopup} variant="secondary" className='btn-primary m-i-end-03'>Close</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={handleSubmit} className='btn-primary m-i-end-03'>Submit</Button> {' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick= { printCountry } className='btn-primary'>Check Country</Button>
              </ButtonGroup>
            </ButtonToolbar>
              
          </Modal.Body>
        </Modal>
        :null
      }
    </div>
    

  )
}

export default CountryEditor;