import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import TenantAppAccordian from './Layout/Nav/TenantAppAccordian';
import TenantTopNav from './Layout/Nav/TenantTopNav';
import SignInPageRoutes from './SignInPageRoutes';

function SmViewPrtLandPage({
    tenantSettings, 
    showNav=false, 
    setShowNav, 
    showNavHandler, 
    windowSize, 
    setSupportModalIsShown, 
    onLinkClick, 
    firstTimeUser,
    onQuery,
    getNewScreenWidth,
    resetToken,
    accounts,
    notifications,
    apiError,
    tooltip,
    curNav,
    accessToken,
    isAuthenticated,
    queryRequest,
    supportModalShown
}) {
  return (
    <Row clasName='content-row'>
        <Container fluid className={showNav ? "profiles-page body-container show" : "profiles-page body-container hide"}>
            <TenantTopNav
                className={showNav ? 'show' : 'hide'}
                tenant={tenantSettings && tenantSettings.tenant ? tenantSettings.tenant : null}
                onQuery={onQuery}
                raiseSupport={setSupportModalIsShown}
                onWidthChange={getNewScreenWidth}
                windowSize={windowSize}
                tenantName={
                    tenantSettings &&
                        tenantSettings.tenant &&
                        tenantSettings.tenant.fields &&
                        tenantSettings.tenant.fields.tenantSlug ?
                        tenantSettings.tenant.fields.tenantslug : ''}
                resetToken={resetToken}
                showFakes={firstTimeUser}
            />
            {
                <Col md={12} onClick={() => setShowNav(!showNav)} className={showNav ? 'top-workspace-nav show' : 'top-workspace-nav hide'}>
                    <TenantAppAccordian
                        tenantApps={
                            tenantSettings &&
                            tenantSettings.tenant &&
                            tenantSettings.tenant.fields &&
                            tenantSettings.tenant.fields.apps ?
                                tenantSettings.tenant.fields.apps : ''}
                        raiseSupport={setSupportModalIsShown}
                        show={showNav}
                        tenant={tenantSettings}
                        showNavHandler={showNavHandler}
                        screenSize='small'
                        onLinkClick={onLinkClick}
                        showFakes={firstTimeUser}
                    />
                </Col>
            }
            <SignInPageRoutes
                tenantSettings={tenantSettings} 
                accounts={accounts} 
                notifications={notifications} 
                apiError={apiError} 
                tooltip={tooltip} 
                showNav={showNav} 
                windowSize={windowSize} 
                curNav={curNav} 
                firstTimeUser={firstTimeUser} 
                accessToken={accessToken} 
                isAuthenticated={isAuthenticated} 
                queryRequest={queryRequest} 
                supportModalShown={supportModalShown}
            />
        </Container>
    </Row>
  )
}

export default SmViewPrtLandPage