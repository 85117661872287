import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table, Form } from 'react-bootstrap'
import Select from 'react-select';
import ObjectSelector from '../ObjectSelector';
import EnumSelector from '../EnumSelector';
import { InfoCircleFill, Trash } from 'react-bootstrap-icons';
import TableHeader from '../TableHeader';
import DateSelector from '../../../DateSelector';
import NumericInput from '../NumericInput';
import ToolTip from '../../Layout/ToolTip';
import ModalDynamic from '../../Layout/ModalDynamic';
import utils from '../../../utils';


function PhaseEditor({ phase, onChange, onDelete, childGeographies, prodChars, userName, apiKey,
  i, open, tooltip, getTooltipContentByKey, errors, gender, minAge, maxAge, minAgeUnit, maxAgeUnit,
  geographyId, appClass }) {
  const [expandedRow, setExpandedRow] = useState(null);
  const [fieldsUpdated, setFieldsUpdated] = useState(new Set());
  const [showWarning, setShowWarning] = useState(false);
  const [targetPop, setTargetPop] = useState(null);
  const [trs, setTrs] = useState(null);



  useEffect(() => {
    async function getTargetPopAndTRS() {
      // For this to work, we have to have:
      // all fields necessary to compute a target population
      // or an explicit target population
      // or an explicite TRS > 10
      if ((!minAge || !maxAge || !geographyId || !minAgeUnit || !maxAgeUnit || !phase.actual_start_year)
        && !phase.target_population
        && (!phase.uptake_or_trs || phase.uptake_or_trs < 10)
      ) {
        setTargetPop(null);
        setTrs(null);
        return;
      }
      const res = await utils.db_api_post('get_target_pop_and_trs', apiKey, {
        gender: gender,
        min_age: minAge,
        min_age_unit: minAgeUnit,
        max_age: maxAge,
        max_age_unit: maxAgeUnit,
        container_quantity: phase.product_characteristic?.container_quantity,
        start_year: phase.actual_start_year,
        uptake_or_trs: phase.uptake_or_trs,
        target_population: phase.target_population,
        geography_id: geographyId,
        multiplier: phase.multiplier,
        wastage: phase.wastage
      })
      if (res) {
        setTargetPop(res.target_population)
        setTrs(res.trs)
      }
    }
    getTargetPopAndTRS()
  }, [gender, minAge, maxAge, minAgeUnit, maxAgeUnit, phase.product_characteristic?.container_quantity,
    phase.actual_start_year, phase.uptake_or_trs, phase.target_population, geographyId, apiKey, phase.multiplier,
    phase.wastage])

  const actualDateFields = new Set([
    'actual_start_day', 'actual_start_month', 'actual_start_year',
    'actual_end_day', 'actual_end_month', 'actual_end_year'
  ])


  function getGeographySelection() {
    const current_geo_ids = new Set(phase.target_geographies.map((tg) => tg.geographic_target_id));
    const filteredGeos = childGeographies.filter((cg) => current_geo_ids.has(cg.value));
  
    // If all geographies are selected, return only the "All Geographies Selected" label
    if (filteredGeos.length === childGeographies.length) {
      return [{ label: "All Geographies Selected", value: "all" }];
    }
  
    return filteredGeos;
  }
  


  function handleGeoSelect(selectedOptions) {
    const isSelectingAll = selectedOptions.some(option => option.value === "all");
  
    let geo_ids;
    if (isSelectingAll) {
      // Filter childGeographies to only include admin1 geographies 
      geo_ids = childGeographies
        .filter(cg => !cg.label.includes(" -- ")) // filter out geographies that have -- in the name
        .map(cg => ({ geographic_target_id: cg.value }));
    } else {
      // Otherwise, keep only the selected ones
      geo_ids = selectedOptions
        .filter(option => option.value !== "all")
        .map(g => ({ geographic_target_id: g.value }));
    }
  
    const fieldsUpdatedStr = handleFieldsUpdated("geography");

    onChange({
      ...phase,
      target_geographies: geo_ids,
      fields_updated: fieldsUpdatedStr,
      user_updated: userName
    });
  }
  

  

  function handleFieldsUpdated(field) {
    const tempFieldUpdates = new Set(fieldsUpdated);
    setFieldsUpdated(tempFieldUpdates);
    tempFieldUpdates.add(field);
    return Array.from(tempFieldUpdates).join(", ")
  }
  
  
  

  function handleProdCharChange(prodChar) {
    const fieldsUpdatedStr = handleFieldsUpdated('product')
    onChange({
      ...phase,
      product_characteristic_id: prodChar.product_characteristic_id,
      product_characteristic: prodChar,
      fields_updated: fieldsUpdatedStr,
      user_updated: userName
    })
  }

  function handleFieldUpdate(field, value) {
    if (value === '') {
      value = null;
    }
    const fieldsUpdatedStr = handleFieldsUpdated(field);
    if (field === "phase_status") {
      phase.delay_reason = null;
    }
    if (field === "actual_start_year") {
      phase.actual_start_month = null;
    }
    if (field === "actual_end_year") {
      phase.actual_end_month = null;
    }
    const tempPhase = { ...phase };
    tempPhase[field] = value;
    tempPhase.fields_updated = fieldsUpdatedStr;
    tempPhase.user_updated = userName;

    // When updating an actual date and the phase has not been committed to the db (indicated by the presence of an id)
    // update the actual fields
    if (actualDateFields.has(field) && !phase.dose_sequence_id) {
      const plannedField = field.replace('actual', 'planned');
      tempPhase[plannedField] = value;
    }
    onChange(tempPhase);
  }

  // Function to toggle row expansion
  const toggleRow = index => {
    setExpandedRow(expandedRow === index ? null : index);
  };


  // show delete warning
  const showWarningModal = () => {
    setShowWarning(true)
  }

  // close delete warning
  const handleClose = () => {
    setShowWarning(false)
  }
  // add warning icon to dynamic buttons in modal
  const addWarningIcon = (name) => {
    return (
      <>
        <InfoCircleFill className='btn-icon' />{name}
      </>
    )
  }

  // add trash icon to dynamic buttons in modal
  const addTrashIcon = (name) => {
    return (
      <>
        {name}<Trash className='btn-icon' />
      </>
    )
  }

  // warning modal body content
  const getModalBody = () => {
    return (
      <div className='m-b-end-07'>
        <p className='body-med txt-primary'>Are you sure you want to delete this Phase?</p>
        <p className='body-med txt-tertiary'>This change is permanent.</p>
      </div>
    )
  }


  // pop up alerting the user about the delete
  const deleteConfirmationModal = {
    title: addWarningIcon('Warning'),
    body: getModalBody(),
    app: 'Global Health Alchemy',
    subName: addTrashIcon('Delete')
  }




  return (
    <>

      {!open ?
        <>
          <Table striped hover size="sm">
            <thead className="table-heading">
              <tr>
                <TableHeader title="Phase" />
                <TableHeader title="Phase ID" />
                <TableHeader title="Start Date *" />
                <TableHeader title="End Date" />
                <TableHeader title="Product Characteristic" />
                <TableHeader title="Uptake or TRS" />
                <TableHeader title="Multiplier" />
                <TableHeader title="Target Population" />
                <TableHeader title="Wastage" />
                <TableHeader title="Computed TRS" />
                <TableHeader title="" />
              </tr>
            </thead>

            <tbody className="table-body" key={i}>
              <React.Fragment key={i}>
                <tr className="p-i-start-02">
                  <td className="m-i-start-04">{phase.phaseNumber}</td>
                  <td className="m-i-start-04">{phase.phase_id}</td>

                  <td>
                    <div className='min-width-220'>
                      <DateSelector
                        selectedYear={phase.actual_start_year}
                        setSelectedYear={(value) => handleFieldUpdate("actual_start_year", value)}
                        selectedMonth={phase.actual_start_month}
                        setSelectedMonth={(value) => handleFieldUpdate("actual_start_month", value)}
                        isYearEditable={true}
                        isMonthEditable={true}
                        showDaySelector={false}
                      />
                    </div>
                  </td>

                  <td>
                    <div className='min-width-220'>
                      <DateSelector
                        selectedYear={phase.actual_end_year}
                        setSelectedYear={(value) => handleFieldUpdate("actual_end_year", value)}
                        selectedMonth={phase.actual_end_month}
                        setSelectedMonth={(value) => handleFieldUpdate("actual_end_month", value)}
                        isYearEditable={true}
                        isMonthEditable={true}
                        showDaySelector={false}
                      />
                    </div>
                  </td>

                  <td>
                    <ObjectSelector
                      className='select m-i-end-02'
                      obj={phase.product_characteristic}
                      data={prodChars}
                      type="product_characteristic"
                      onChange={handleProdCharChange}
                    />
                  </td>

                  <td>
                    <NumericInput
                      className="textarea"
                      placeholder="##.#"
                      value={phase.uptake_or_trs !== null ? phase.uptake_or_trs : null}
                      onChange={(value) => handleFieldUpdate("uptake_or_trs", value)}
                      allowDecimals={true}
                      allowCommas={true}
                    />
                  </td>

                  <td>
                    <NumericInput
                      className="textarea"
                      placeholder="##.#"
                      value={phase.multiplier !== null ? phase.multiplier : null}
                      onChange={(value) => handleFieldUpdate("multiplier", value)}
                      allowDecimals={true}
                      allowCommas={true}
                    />
                  </td>

                  <td>
                    <NumericInput
                      className="textarea"
                      placeholder="##"
                      value={phase.target_population !== null ? phase.target_population : null}
                      onChange={(value) => handleFieldUpdate("target_population", value)}
                      allowDecimals={false}
                      allowCommas={true}
                      disabled={false}
                    />
                  </td>

                  <td>
                    <NumericInput
                      className="textarea"
                      placeholder="##.#"
                      value={phase.wastage !== null ? phase.wastage : null}
                      onChange={(value) => handleFieldUpdate("wastage", value)}
                      allowDecimals={true}
                      allowCommas={true}
                      disabled={false}
                    />
                  </td>

                  <td>
                    <NumericInput
                      className="textarea"
                      placeholder="##"
                      value={trs ? trs : null}
                      onChange={(value) => handleFieldUpdate("trs", value)}
                      allowDecimals={true}
                      allowCommas={true}
                      disabled={true}
                    />
                  </td>

                  <td>
                    <button
                      className="btn-sm-invisible-blue"
                      onClick={() => toggleRow(i)}
                      aria-controls="example-collapse-text"
                      aria-expanded={expandedRow}
                    >
                      {expandedRow === i ? "Collapse" : "Expand"}
                    </button>
                  </td>
                </tr>



                {/* Expanded Phase */}
                {expandedRow === i && (
                  <tr>
                    <td colspan="11">
                      <div className="padded-row bg-surface-secondary p-02 full-width">

                        {/* First Row */}
                        <Row>
                          <Col lg={2}>
                            <div className="input-text">
                              <strong>Phase Number:</strong> {phase.phaseNumber}
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div className="input-text">Phase Status *</div>
                            <EnumSelector
                              className='select'
                              enum_type="ce_status"
                              apiKey={apiKey}
                              disableCreate={true}
                              value={phase.phase_status}
                              onChange={(e) => handleFieldUpdate("phase_status", e.label)}
                            />
                          </Col>

                          <Col lg={2}>
                            <div className="input-text">Delay Reason</div>
                            <EnumSelector
                              className='select'
                              enum_type="ce_delay_reason"
                              apiKey={apiKey}
                              disableCreate={true}
                              value={
                                (phase.phase_status === "Might postpone" ||
                                  phase.phase_status === "Postponed" ||
                                  phase.phase_status === "Cancelled" ||
                                  phase.phase_status === "Suspended mid-campaign")
                                  ? phase.delay_reason
                                  : null
                              }
                              isClearable={true}
                              disabled={
                                !(phase.phase_status === "Might postpone" ||
                                  phase.phase_status === "Postponed" ||
                                  phase.phase_status === "Cancelled" ||
                                  phase.phase_status === "Suspended mid-campaign")
                              }
                              onChange={(e) => handleFieldUpdate("delay_reason", e.label)}
                            />
                          </Col>

                          <Col lg={2}>
                            <div className="input-text">Uptake or TRS <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_uptake_or_trs')} /></div>
                            <NumericInput
                              className="textarea"
                              placeholder="##.#"
                              value={phase.uptake_or_trs !== null ? phase.uptake_or_trs : ""}
                              onChange={(value) => handleFieldUpdate("uptake_or_trs", value)}
                              allowDecimals={true}
                              allowCommas={false}
                            />
                          </Col>
                          <Col lg={2}>
                            <div className="input-text">Quantity Requested <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_quantity_requested')} /></div>
                            <NumericInput
                              className="textarea"
                              placeholder="##"
                              value={phase.quantity_requested !== null ? phase.quantity_requested : null}
                              onChange={(value) => handleFieldUpdate("quantity_requested", value)}
                              allowDecimals={false}
                              allowCommas={true}
                            />
                          </Col>
                          <Col lg={2}>
                            <div className="input-text">Multiplier <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_multiplier')} /></div>
                            <NumericInput
                              className="textarea"
                              placeholder="##.#"
                              value={phase.multiplier !== null ? phase.multiplier : null}
                              onChange={(value) => handleFieldUpdate("multiplier", value)}
                              allowDecimals={true}
                              allowCommas={true}
                            />
                          </Col>

                        </Row>

                        {/* Second Row */}
                        <Row>
                          <Col lg={6}>
                            <DateSelector
                              yearLabel="Planned Start Year *"
                              monthLabel="Planned Start Month"
                              dayLabel="Planned Start Day"
                              selectedYear={phase.planned_start_year}
                              setSelectedYear={(value) => handleFieldUpdate('planned_start_year', value)}
                              selectedMonth={phase.planned_start_month}
                              setSelectedMonth={(value) => handleFieldUpdate('planned_start_month', value)}
                              selectedDay={phase.planned_start_day}
                              setSelectedDay={(value) => handleFieldUpdate('planned_start_day', value)}
                              isYearEditable={false}
                              isMonthEditable={false}
                              isDayEditable={false}
                              showDaySelector={true}
                            />
                          </Col>
                          <Col lg={6}>
                            <DateSelector
                              yearLabel="Planned End Year"
                              monthLabel="Planned End Month"
                              dayLabel="Planned End Day"
                              selectedYear={phase.planned_end_year}
                              setSelectedYear={(value) => handleFieldUpdate('planned_end_year', value)}
                              selectedMonth={phase.planned_end_month}
                              setSelectedMonth={(value) => handleFieldUpdate('planned_end_month', value)}
                              selectedDay={phase.planned_end_day}
                              setSelectedDay={(value) => handleFieldUpdate('planned_end_day', value)}
                              isYearEditable={false}
                              isMonthEditable={false}
                              isDayEditable={false}
                              showDaySelector={true}
                            />
                          </Col>
                        </Row>


                        {/* Third Row */}
                        <Row>
                          <Col lg={6}>
                            <DateSelector
                              yearLabel="Actual Start Year *"
                              monthLabel="Actual Start Month"
                              dayLabel="Actual Start Day"
                              selectedYear={phase.actual_start_year}
                              setSelectedYear={(value) => handleFieldUpdate('actual_start_year', value)}
                              selectedMonth={phase.actual_start_month}
                              setSelectedMonth={(value) => handleFieldUpdate('actual_start_month', value)}
                              selectedDay={phase.actual_start_day}
                              setSelectedDay={(value) => handleFieldUpdate('actual_start_day', value)}
                              isYearEditable={true}
                              isMonthEditable={true}
                              isDayEditable={true}
                              showDaySelector={true}
                              isClearable={true}
                            />
                          </Col>
                          <Col lg={6}>
                            <DateSelector
                              yearLabel="Actual End Year"
                              monthLabel="Actual End Month"
                              dayLabel="Actual End Day"
                              selectedYear={phase.actual_end_year}
                              setSelectedYear={(value) => handleFieldUpdate('actual_end_year', value)}
                              selectedMonth={phase.actual_end_month}
                              setSelectedMonth={(value) => handleFieldUpdate('actual_end_month', value)}
                              selectedDay={phase.actual_end_day}
                              setSelectedDay={(value) => handleFieldUpdate('actual_end_day', value)}
                              isYearEditable={true}
                              isMonthEditable={true}
                              isDayEditable={true}
                              showDaySelector={true}
                            />
                          </Col>
                        </Row>

                        {/* Fourth Row */}
                        <Row>
                          <Col lg={4}>
                            <div className="input-text">Target Population <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_phase_target_pop')} /></div>
                            <NumericInput
                              className="textarea"
                              value={phase.target_population !== null ? phase.target_population : ""}
                              onChange={(value) => handleFieldUpdate("target_population", value)}
                              allowDecimals={false}
                              allowCommas={true}
                            />
                          </Col>

                          <Col lg={4}>
                            <div className="input-text">Target Geographies <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_target_geographies')} /></div>
                            <Select
                              options={[{ label: "Select All", value: "all" }, ...childGeographies]}
                              value={getGeographySelection(phase.target_geographies)}
                              isMulti={true}
                              onChange={handleGeoSelect}
                            />
                          </Col>


                          <Col lg={4}>
                            <div className="input-text">Wastage <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_wastage')} /></div>
                            <NumericInput
                              className="textarea"
                              placeholder="##.#"
                              value={phase.wastage !== null ? phase.wastage : null}
                              onChange={(value) => handleFieldUpdate("wastage", value)}
                              allowDecimals={false}
                              allowCommas={true}
                            />
                          </Col>
                        </Row>

                        {/* Fifth Row */}
                        <Row>
                          <Col lg={4}>
                            <div className="input-text">Info Source *  <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_info_source')} /></div>
                            <EnumSelector
                              enum_type="info_source"
                              apiKey={apiKey}
                              disableCreate={true}
                              value={phase.info_source}
                              onChange={(e) => handleFieldUpdate("info_source", e.label)}
                            />
                          </Col>
                          <Col lg={4}>
                            <div className="input-text">Info Source Date *  <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_info_source_date')} /></div>
                            <input
                              className="form-ctrl"
                              type="date"
                              value={phase.info_source_date}
                              onChange={(e) => handleFieldUpdate("info_source_date", e.target.value)}
                            />
                          </Col>
                          <Col lg={4}>
                            <div className="input-text">Info Source Detail <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_info_source_detail')} /></div>
                            <textarea
                              className="textarea"
                              placeholder="Info Source Detail"
                              value={phase.info_source_detail}
                              onChange={(e) => handleFieldUpdate("info_source_detail", e.target.value)}
                            ></textarea>
                          </Col>
                        </Row>

                        {/* Sixth Row */}
                        <Row>
                        <Col lg={4}>
                            <div className="input-text">Computed TRS</div>
                            <NumericInput
                              className="textarea"
                              placeholder="##"
                              value={trs ? trs : null}
                              onChange={(value) => handleFieldUpdate("trs", value)}
                              allowDecimals={true}
                              allowCommas={true}
                            />
                          </Col>

                          <Col lg={4}>
                            <div className="input-text">Product Characteristic</div>
                            <ObjectSelector
                              obj={phase.product_characteristic}
                              data={prodChars}
                              type="product_characteristic"
                              onChange={handleProdCharChange}
                            />
                          </Col>
                          <Col lg={4}>
                            <div className="input-text">Has Unknown Geographies?</div>
                            <Form.Check
                              checked={phase.has_unknown_geo}
                              onChange={() => handleFieldUpdate('has_unknown_geo', !phase.has_unknown_geo)}
                            />
                          </Col>
                        </Row>

                        {/* Final Row */}
                        <Row>
                          <Col lg={6}>
                            <div className="input-text">Internal Notes <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_internal_notes')} /></div>
                            <textarea
                              className="textarea"
                              placeholder="Info Source Detail"
                              value={phase.internal_notes}
                              onChange={(e) => handleFieldUpdate("internal_notes", e.target.value)}
                            ></textarea>
                          </Col>
                          <Col lg={6}>
                            <div className="input-text">External Notes <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_external_notes')} /></div>
                            <textarea
                              className="textarea"
                              value={phase.external_notes}
                              onChange={(e) => handleFieldUpdate('external_notes', e.target.value)}
                            ></textarea>
                          </Col>
                        </Row>

                      <Row>
                        <Col>
                            <div className="input-text">
                              <p><strong>Target Population: </strong>{targetPop ? utils.formatNumberWithCommas(targetPop) : ""}</p>
                              <p><strong>Computed TRS: </strong>{trs ? utils.formatNumberWithCommas(trs) : ""}</p>
                              <p><strong>User Updated: </strong>{phase.user_updated}</p>
                              <p><strong>Fields Updated: </strong>{" "}{Array.from(fieldsUpdated).join(",")}</p>
                            </div>
                          </Col>
                      </Row>


                        <Button onClick={showWarningModal} className="btn-primary m-b-start-02">
                          Delete Phase
                          <Trash className="m-i-start-02" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                )}


              </React.Fragment>
            </tbody>
          </Table>
          {errors && errors.length > 0 && (
            <div className="error">
              {errors.map((error, index) => (
                <div key={index} className="error-message">{error}</div>
              ))}
            </div>
          )}
        </>
        : null
      }
      <ModalDynamic
        classes={{ modal: 'warning-border' }}
        show={showWarning}
        content={deleteConfirmationModal}
        handleClose={handleClose}
        submitRequest={onDelete}
      />

    </>
  );
}

export default PhaseEditor;