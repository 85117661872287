import React, { useRef } from 'react';
import * as XLSX from "xlsx";
import { Button } from 'react-bootstrap'
import _ from 'lodash';

import utils from "../../../utils"
import '../../../styles/layout/_layout.scss'

const years = _.range(2000,2041)

const wideToLong=(arr)=>{
  return arr.map((row)=>{
      return years.map((y)=>{
        return {vaccine:row.Vaccine,
          price_tier:row['Price Tier'],
          market: row.Market,
          forecast_type: row['Forecast Type']+'-Forecast',
          year:y,
          price:row[y]
        }
      })
    }).flat()
}

const importData = (sheetName, workbook)=>{
  const worksheet = workbook.Sheets[sheetName];
  const json = XLSX.utils.sheet_to_json(worksheet, {defval: null, range:1});
  const formatted = wideToLong(json)
  return formatted
}

const scanForNulls = (data) => {
  let errors = new Set()
  for (let i = 0; i<data.length; i++){
    if (!data[i].vaccine){
      errors.add("Vaccine must never be missing on any sheet")
    } else if (!data[i].price_tier){
      errors.add("Price tier must never be missing on any sheet")
    } else if (!data[i].market){
      errors.add('Market can never be missing on any sheet')
    } else if (!data[i].forecast_type){
      errors.add('Forecast type can never be missing on any sheet')
    } else if (!data[i].year){
      errors.add('Year can never be missing on any sheet')
    }
  }
  return Array.from(errors)
}

const PriceProjectionImporter=({apiKey, setXferMessage, setErrors})=>{
  const ref = useRef();
  
  /*--------------------------State variables---------------------------*/
  async function readData(e){
    
    try{
      e.preventDefault();
      if (e.target.files) {
          setErrors([])
          setXferMessage("Reading data")
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const linearData = importData('Linear-Forecast', workbook)
            const expertData = importData('Expert-Forecast', workbook)
            const displayData = importData('Display-Forecast', workbook)
            const allData = [...linearData, ...expertData, ...displayData]
            setXferMessage("Scanning for obvious errors")
            const errors = scanForNulls(allData)
            if (errors.length>0){
              setErrors(errors)
              setXferMessage(null)
              ref.current.value=null;
              return
            }
            setXferMessage("Attempting to upload")
            const result = await utils.db_api_post('update_price_projections', apiKey, allData)
            
            if(result.length > 0){
              setErrors(result)
              setXferMessage(null)
              ref.current.value=null;
            }
            setXferMessage("Done importing")
            ref.current.value=null;
          };
          reader.readAsArrayBuffer(e.target.files[0]);
      }
    } catch(err) {
      setXferMessage("Transfer failed error: "+err)
    }
      
  }

  const handleInputClick=()=>{
    ref.current.click()
  }

  return (
      <>
        <Button className='btn-primary bg-blue-300 m-b-start-03 m-i-end-02' onClick={handleInputClick}>Import Price Projections</Button>
        <input ref={ref} type = "file" onChange = {readData} style={{"display":"none"}}/>
      </>

  )
}
export default PriceProjectionImporter;