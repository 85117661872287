import React, { useEffect, useState } from 'react';
import EditableTextCell from '../PMT/EditableTextCell';
import PMTCONSTANTS from '../PMT/pmtConstants';
import { Badge } from 'react-bootstrap';
import utils from '../../utils';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';

function CountryActivitiesTable({ 
    iso, 
    updatedActivity={}, 
    postActivityUpdate, 
    apiKey = null, 
    appName = null, 
    addFormData, 
    appliedFilters, 
    inclDel = false, 
    tenant, 
    groupMembers,  
}) {
    let [activities, setActivities] = useState();
    let [callActivities, setCallActivities] = useState(true);
    let [loading, setLoading] = useState(false)

    useEffect(() => {
        (async () => {
            if(updatedActivity && updatedActivity.dh_antigen) {
                setLoading(true);
                getCountryActivities(iso, appName, inclDel, apiKey)
            }
        })()
    //eslint-disable-next-line
    },[updatedActivity, appName, inclDel, apiKey, iso])

    useEffect(() => {
        (async () => {
            if (appName && apiKey && callActivities === true) {
                await getCountryActivities(iso, appName, inclDel, apiKey);
            }
        })()
        setCallActivities(false)
        //eslint-disable-next-line
    }, [callActivities, appName, apiKey, callActivities, inclDel, iso])

    useEffect(() => {
        setCallActivities(true);
    }, [iso])

    //gets a list of Activities related to each Country
    const getCountryActivities = async (ISO, app, inDel, key) => {
        setLoading(true);
        let url = PMTCONSTANTS[app.fields.apptype].GET_ACTIVITIES_BY_COUNTRY;
        const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${url}?ISO=${ISO}&include_deleted=${inDel}`, {

            headers: {
                'Content-Type': 'application/json',
                'access_token': key
            }
        })
        if (result['status'] === 403) {
            alert("Invalid API key, access denied")
        }

        let resJson = await result.json();
        let sortedActivities = resJson.sort((a,b) => a.dh_antigen.localeCompare(b.dh_antigen))

        setActivities(sortedActivities)
        setLoading(false);
    }

    // Gets the updated change and adds it to the state
    const handleActivityUpdate = (cellVal) => {
        //receives the rowID, colname, and new value
        let tempData = [...activities];
        let activityToUpdate;

        //Update the activity with the new value  
        let updatedActivities = tempData.map((activity) => {
            if (activity.activity_id === cellVal.rowID) {
                activity[cellVal.colname] = cellVal.value;
                activityToUpdate = activity;
            }
            return activity;
        })
        //calls the API one level up to update the activity in the DB
        postActivityUpdate(activityToUpdate);
        setActivities(updatedActivities)
    }

    //Gets the expected date format YYYY-MM-DD
    const getFormattedDate = (date) => {
        let newDate = date.split('T')
        newDate = newDate[0].split('-');
        return `${newDate[2]}-${newDate[1]}-${newDate[0]}`
    }

    // updates the comment and sends to DB
    const commentHandler = (e, comments) => {
        let commentsArr = [];
        let commentObj = {};
        let userInfo = utils.findUserInfo(tenant.ourUserID, groupMembers)

        //Adds comment info to obj
        commentObj.comment_id = comments && comments.length > 0 && comments[0].comment_id ? comments[0].comment_id : null;
        commentObj.activity_id = e.rowID;
        commentObj.owner = userInfo[0].displayName;
        commentObj.created_on = new Date().toISOString();
        commentObj.comment_value = e.value;
        commentsArr.push(commentObj);
        handleActivityUpdate({ rowID: e.rowID, colname: 'comments', value: commentsArr })
    }

    const filterToActivitiesSelected = (list, selActs) => {

        let filteredActList = []

        if (selActs && selActs.length > 0) {
            list.forEach((act) => {
                if (selActs.includes(act.dh_antigen)) {
                    filteredActList.push(act)
                }
            });
          return filteredActList;
        } else {
          return list;
        }
    }

    return (
        !loading ?
            activities && activities.length > 0 ?
            filterToActivitiesSelected(activities, appliedFilters).map((nestedItem) => {
                    return (
                        <table className='full-width'>
                            <tr>
                                <td onClick={() => addFormData(nestedItem)} className='doubleClick px-width-250 body-med p-i-start-05 p-b-start-03 vertical-align-top'>{nestedItem.dh_antigen ? nestedItem.dh_antigen : ''}</td>
                                <td onClick={() => addFormData(nestedItem)} className='doubleClick px-width-100 body-med vertical-align-top p-b-start-03'>{nestedItem.priority}</td>
                                <td onClick={() => addFormData(nestedItem)} className='doubleClick px-width-225 body-med vertical-align-top p-b-start-03'>
                                    {
                                        nestedItem.status ?
                                            <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY[nestedItem.status.toLowerCase()]} txt-primary label-small p-i-04 p-b-02 border-radius-lg`}>{nestedItem.status ? nestedItem.status : ''}</Badge>
                                            : ''
                                    }
                                </td>
                                <td onClick={() => addFormData(nestedItem)} className='px-width-250'>
                                    <EditableTextCell
                                        className={'m-i-end-06'}
                                        rowId={nestedItem.activity_id}
                                        colname={'details'}
                                        initialValue={nestedItem.details ? nestedItem.details : ''}
                                        onUpdate={(cellVal) => handleActivityUpdate(cellVal)}
                                    />
                                </td>
                                <td onClick={() => addFormData(nestedItem)} className='px-width px-width-100'>
                                    <EditableTextCell
                                        className='resize-none p-i-start-06'
                                        rowId={nestedItem.activity_id}
                                        colname={'year'}
                                        initialValue={nestedItem.year ? nestedItem.year : ''}
                                        onUpdate={(cellVal) => handleActivityUpdate(cellVal)}
                                    />
                                </td>
                                <td onClick={() => addFormData(nestedItem)} className='px-width-225 p-i-start-06'>
                                    <EditableTextCell
                                        rowId={nestedItem.activity_id}
                                        className='px-width-200'
                                        colname={'source'}
                                        initialValue={nestedItem.source ? nestedItem.source : ' '}
                                        onUpdate={(cellVal) => handleActivityUpdate(cellVal)}
                                    />
                                </td>
                                <td className='px-width-225 p-i-start-06 vertical-align-top'>
                                    <div>{nestedItem.activity_type ? nestedItem.activity_type : ''}</div>
                                </td>
                                <td onClick={() => addFormData(nestedItem)} className='px-width-100 vertical-align-top'>
                                    <div>{nestedItem.updated_date ? getFormattedDate(nestedItem.updated_date) : ''}</div>
                                </td>
                                <td onClick={() => addFormData(nestedItem)} className='px-width-250'>
                                    <EditableTextCell
                                        rowId={nestedItem.activity_id}
                                        className='px-width-250'
                                        colname={'comments'}
                                        initialValue={nestedItem.comments && nestedItem.comments.length > 0 ? nestedItem.comments[0].comment_value : ' '}
                                        onUpdate={(cellVal) => commentHandler(cellVal, nestedItem.comments)}
                                    />
                                </td>
                            </tr>
                        </table>
                    )
                })
            : <div>There are no antigens for this country</div>
        : <ListSkeletonLoader preview={false} /> 
    )
}

export default CountryActivitiesTable;