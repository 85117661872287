// useEffects are React18 ready
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Button } from 'react-bootstrap'

import StatefulSearchTable from '../StatefulSearchTable';
import EnumEditor from './EnumEditor'
import {useToast} from '../../ToastProvider'
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';

const metaData=[
  {title:'LB Word', dispField:'enum', filterField:'enum'},
  {title:'Replacement', dispField:'replacement', filterField:'replacement', isMulti:true},
  {title:'Alternatives', dispField:'alts'},
  {title:'Sort Order', dispField:'sort_order'}
]

const sortData=[
  {title:"Sort Order", field:'sort_order'},
  {title:"Word", field:'enum'}
]

async function fetchEnums(setEnums, apiKey, addToast, enumType) {
  setEnums([]);
  let result = await utils.getData("get_enums", apiKey, `?enum_type=${enumType}&include_deleted=True`);

  if (typeof result === 'string') {
    addToast({ title: 'Failed to load data. Error code 4-d', body: result });
    return;
  }

  let temp = result.map(obj => ({
    ...obj,
    replacement: obj.replacement?.enum,
    alts: obj.alt_enums.map(e => e.alt_enum).join(','),
    sort_order: obj.sort_order?.toString()
  }));

  setEnums(temp);
}

async function fetchEnumTypes(setEnumTypes, apiKey, addToast) {
  setEnumTypes([]);
  let result = await utils.getData("get_enum_types", apiKey);

  if (typeof result === 'string') {
    addToast({ title: 'Failed to load data. Error code 4-e', body: result });
    return;
  }

  setEnumTypes(result.map(r => ({ label: `${r.table_name}(${r.field})`, value: r.enum_type })));
}



function EnumSearch({apiKey, appClass, editor}) {
  // Local representation with data augmentations
  const [enums, setEnums] = useState([]);
  const [enumTypes, setEnumTypes] = useState([]);
  // Product that gets edited when double clicked
  const [enumId, setEnumId] = useState(null)
  // A toggle for showing the edit product modal
  const [showEditEnum, setShowEditEnum] = useState(false)
  // For storing the selected Enum type
  const [enumType, setEnumType] = useState({label:'prod.product(development_phase)', value:'development_phase'});
  const [affectedFields, setAffectedFields] = useState([])
  
  const {addToast} = useToast();
  const [getFromLocalStore, setGetFromLocalStore] = useState(false);

  


  useEffect(() => {
    if (editor !== 'Synonyms' || showEditEnum) return;
    fetchEnums(setEnums, apiKey, addToast, enumType.value);
    fetchEnumTypes(setEnumTypes, apiKey, addToast);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [editor, showEditEnum, apiKey, enumType]);



  useEffect(()=>{
    // Filter to enums of the same type, excluding the one currently selected.
    setAffectedFields(enumTypes.filter((et) => et.value === enumType.value && et.label !==enumType.label).map((et) => {return et.label}))
  }, [enumType, enumTypes])


  function toggleShowEditEnum() {
    setShowEditEnum(!showEditEnum)
  };

  function createNew() {
    toggleShowEditEnum()
    setEnumId(null)
    setGetFromLocalStore(false);
  }

  function update(lbWord) {
    toggleShowEditEnum()
    setEnumId(lbWord.enum_id)
    setGetFromLocalStore(false);
  }

  function openLocalStoreWord(){
    setShowEditEnum(true);
    setEnumId(null);
    setGetFromLocalStore(true);
  }

  // Passed into search table to add buttons to the left of the required buttons
  const toolbarButtons = () => {
    return (
      <>
        <Select 
          className='select-lg' 
          options={enumTypes} 
          onChange={(e) => setEnumType(e)}
          value={enumType}
        /> {' '}
        <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
      </>
    )
  }

  const formatList = (theList) => {
    if(!theList.length || theList.length === 0){
      return;
    }
    if (theList.length === 1){
      return theList[0]
    } else if (theList.length === 2){
      return theList[0] + ' and ' + theList[1]
    } else {
      const last = theList.pop();
      return theList.join(', ') + ', and ' + last
    }
  }

  return (
    <div>
      {
        affectedFields.length > 0 &&
        <div>
          WARNING: Changes to these options will also affect {formatList(affectedFields)}
        </div>
      }
      {showEditEnum ?
        <EnumEditor 
          id={enumId}
          handleSubmit={toggleShowEditEnum}
          closePopup={toggleShowEditEnum}
          apiKey={apiKey}
          appClass={appClass}
          getFromLocalStore={getFromLocalStore}
          toastClick={openLocalStoreWord}
          enumType={enumType.value}
        />
        : null
      }
      <StatefulSearchTable
        data={enums}
        metaData={metaData}
        sortData={sortData}
        onRowClick={update}
        apiKey={apiKey}
        editor={editor}
        module='enums'
        rowsPerPage={20}
        toolbarButtons={toolbarButtons}
        appClass={appClass}
      />
    </div>
  )
}

export default EnumSearch;