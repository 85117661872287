import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import ArticleItem from '../News/ArticleItem';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';

import utils from '../../utils';
import TrackingUtils from '../../TrackingUtils';

function CEArticleDisplay({ workspace, tenantID }) {

  let { articleSlug } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState();
  const { instance, accounts } = useMsal();

  const getAPIKey = async () => {
    const token = await utils.getAccessToken(instance, accounts[0], { scopes: ["User.Read"] });
    const response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {
        headers: { Authorization: `Bearer ${token.accessToken}` },
    });
    const { value } = await response.json();
    return value;
};

  //hubspot analytics
  useEffect( () => {
    utils.initializeTracking();
  }, []);

  
  const articleItemAdapter = (rawArticle) => {
    let retArticleShape = { ...rawArticle.fields }
    retArticleShape.smallTitle = rawArticle.fields.title.length > 45 ? rawArticle.fields.title.substring(0,40) + '...' : rawArticle.fields.title;
    return retArticleShape;
  }

  //Gets single article and saves in state
  const getArticle = async () => {
    try {
        const apiKey = await getAPIKey(); 
        const searchObj = {
            engine: "ce-articles",  
            size: 1, 
            page: 1,
            filters: {
                slug: [articleSlug]  
            },
            sort_order: "DATE_DESC",
            tenant_id: tenantID,
        };

        const response = await utils.db_api_post("lb_search", apiKey, searchObj);

        let jsonresponse = response.results[0]; 

        let convArticle = articleItemAdapter(jsonresponse);
        setArticle(convArticle);

        TrackingUtils.sendTrackingInfo(jsonresponse, accounts[0], 'ce-news');

    } catch (error) {
        console.error("Error fetching article:", error);
    }
};


  const handleBadgeClick = (tag) => {
    history.push(`/ce_news/?tag=${encodeURIComponent(tag)}`);
  };

  useEffect(() => {
    setLoading(true);
    getArticle();
    setLoading(false)
    // eslint-disable-next-line
  }, []);

  //convert to article that can work with the ArticleDisplay component

  return (
    <div className='article-display'>
      {
        !loading && article ?
          <>
            <Col>
              <BreadCrumbBar
                appName="CE_NEWS"
                item={article}
                displayFieldName="smallTitle" />
            </Col>
            <ArticleItem
              workspace={workspace}
              article={article}
              handleBadgeClick={handleBadgeClick}
              showBadges={true}
            />
          </>
          :
          <ListSkeletonLoader preview={false} />
      }
    </div>
  )
}

export default CEArticleDisplay;