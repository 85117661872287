import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import OverrideModal from './OverrideModal';
import '../../../styles/layout/_layout.scss'

function OverrideEditor({ overrides, onChange, pageItemName }) {
  const [override, setOverride] = useState(null);
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);

  function handleOverrideChange(override) {
    const temp = [...overrides];
    temp[index] = override;
    setOverride(override)
    onChange(temp);
  }

  function handleRowClick(idx) {
    setIndex(idx);
    setOverride(overrides[idx]);
    setShow(true);
  }

  function addOverride() {
    const temp = [...overrides];
    temp.push({pBI_Override_Filters:[]});
    onChange(temp);
  }
  function deleteOverride(idx) {
    const temp = [...overrides];
    temp.splice(idx, 1);
    onChange(temp);
  }

  return (
    <div>
      <Row>
        <Col xs={10}/>
        <Col>
          <Button className='button-secondary m-i-end-02' onClick={addOverride} variant="secondary">
            Add Override
          </Button>
        </Col>
      </Row>
      <table striped size="sm" className = "table table-striped search-table">
        <thead className='search-table-thead'>
          <tr className='search-table-tr'>
            <th className='search-table-th'>Field Name</th>
            <th className='search-table-th'>Operator</th>
            <th className='search-table-th'>Value</th>
            <th className='search-table-th'>Filter String</th>
            <th className='search-table-th'></th>
          </tr>
        </thead>
        <tbody className = "search-table-tbody">
          {overrides.map((override, index) => {
            return <tr key={index} onDoubleClick={() => handleRowClick(index)}>
              <td className= "search-table-td"><div>{override.conditionFieldName}</div></td>
              <td className= "search-table-td">{override.conditionOperator}</td>
              <td className= "search-table-td">{override.conditionValue}</td>
              <td className= "search-table-td">{override.filterString}</td>
              <td className= "search-table-td"><button className="btn-sm-invisible-red" onClick={() => deleteOverride(index)}>Delete</button></td>
            </tr>
          })

          }
        </tbody>
      </table>
      {
        show&&
        <OverrideModal 
          override={override} 
          onChange={handleOverrideChange}
          close={() => setShow(false)}
          pageItemName={pageItemName}
        />
          
      }

    </div>
    
  )
}

export default OverrideEditor;