import React, {useEffect, useState} from 'react';
import CONSTANTS from '../constants';
import FieldDisplay from './FieldDisplay';
import { Route, useRouteMatch, Switch, Redirect, useParams } from 'react-router-dom';
import BreadCrumbBar from './Layout/Nav/BreadCrumbBar';
import utils  from '../utils';
import BubbleRow from './BubbleRow';

import '../styles/components/_urlDrivenApp.scss';
import RelatedArticleTeaser from './Layout/RelatedArticleTeaser';
import UrlDrivenTopNav from './Layout/Nav/UrlDrivenTopNav';
import ListSkeletonLoader from './Layout/ListSkeletonLoader';

function UrlDrivenAppDisplay({ tenant, content, navs, className=null, appType, defaultPage, displayFieldName, logoFieldToShow=null, showRelatedNewsField=null, bubbleFields=[], hasSubItems=false }) {

  const { path } = useRouteMatch();
  const {slug} = useParams();
  const [ levelThreeNav, setLevelThreeNav ] = useState(null);
  const [ loading, setLoading ] = useState(false)
  const [ activeTab, setActiveTab ] = useState();

  let useNavs = utils.cleanUpNavs(navs, content);

  const valExistsInList = (valToCheck, listToLookAt) => {
    let fnd = listToLookAt.filter( (thisItem) => {
      return thisItem.field.toLowerCase() === valToCheck.toLowerCase();
    });

    return fnd.length > 0;
  }

  useEffect(() => {
    navs.forEach((navGroup) => {
      navGroup.subNav.forEach((subItem) => {
        if (subItem.urlForField === defaultPage) {
          setActiveTab({title: navGroup.groupingTitle, url: subItem.urlForField, level_three: null})
        }
      })
    })
  },[navs, defaultPage])

  const takeOutFieldsInBubbles = (contentToStrip, bubbleFields) => {

    let returnedFields = {};

    Object.keys(contentToStrip).forEach( (thisProp) => {
      if(!valExistsInList(thisProp, bubbleFields)) {
        returnedFields[thisProp] = contentToStrip[thisProp];
      }
    });

    return { fields: returnedFields };

  }

  const getAppTitle = (tnt, appType) => {
    let appInQuestion = tnt.tenant.fields.apps.find( (thisApp) => {
      return thisApp.fields.apptype.toLowerCase() === appType.toLowerCase();
    });

    return appInQuestion && appInQuestion.fields && appInQuestion.fields.appAlternateTitle ? appInQuestion.fields.appAlternateTitle : null;
  };

  const getAppURL = (tnt, appType) => {
    let appInQuestion = tnt.tenant.fields.apps.find( (thisApp) => {
      return thisApp.fields.apptype.toLowerCase() === appType.toLowerCase();
    });

    return appInQuestion && appInQuestion.fields && appInQuestion.fields.customctalink ? appInQuestion.fields.customctalink: CONSTANTS.APPS_BY_APPTYPE[appType];
  };

  const noBubbleContent = takeOutFieldsInBubbles(content.fields, bubbleFields);

  useEffect(()=>{
      setLoading(false)
  },[loading])

  const handleNavSelection = (selection, navURL, title) => {
    setLoading(true)
    setLevelThreeNav(selection);
    setActiveTab({title: title, url: navURL, level_three: selection})
  }

  return (
   
      <>
        <BreadCrumbBar 
          appName={appType} 
          appAltTitle={getAppTitle(tenant, appType)}
          displayFieldName={displayFieldName} 
        />
        <div className={`app-page-display ${appType}`}>
        <h1 className='h1 m-b-start-08 m-b-end-08'>
          {getAppTitle(tenant, appType) || content.fields.toolName || content.fields.name || ''}
        </h1>
          <UrlDrivenTopNav
            apppath={getAppURL(tenant, appType)}
            navs={useNavs} 
            subItems={hasSubItems}
            fields={noBubbleContent}
            activeTab={activeTab}
            sendNavItemToShow={handleNavSelection}
            tenantSettings={tenant}
            subUrlVal={slug}
          />
          <Switch>
            <Route exact path={`${path}/:infoID`}>
                {
                  bubbleFields && bubbleFields.length > 0 ?
                    <BubbleRow itemArray={bubbleFields} objToRender={content} />
                  : null
                }
                {
                  showRelatedNewsField ?
                    <RelatedArticleTeaser
                    tenant={tenant}
                    entity={content} 
                    entityType={''} 
                    header='Related News & Company Intelligence'
                    tagQuery={false}
                    searchTerm={slug}
                    />
                  : null
                }
                {
                  !loading?
                  <FieldDisplay 
                    className={className}
                    fields={noBubbleContent} 
                    fieldsDescr={navs} 
                    infoToShow={defaultPage} 
                    levelThreeNav={levelThreeNav}
                    loading={loading}
                    tenant={tenant} />
                  : <ListSkeletonLoader preview={false} />
                }
            </Route>
            <Redirect exact from={`${path}/`} to={`${path}/${defaultPage}`} />
          </Switch>
        </div>
      </>
  )
}
export default UrlDrivenAppDisplay;