import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import ProductSearch from './Product/ProductSearch';
import TxSearch from './Tx/TxSearch';
import VaccineSearch from './Vaccine/VaccineSearch';
import OrganizationSearch from './Organization/OrganizationSearch';
import EnumSearch from './Enum/EnumSearch';
import DemandSearch from './Demand/DemandSearch';
import CountrySearch from './Country/CountrySearch';
import ActivitySearch from './CE/ActivitySearch';
import PriceSearch from './Price/PriceSearch';
import Amadya from './AIAssistants/Amadya';
import GVMMAssistant from './AIAssistants/GVMMAssistant';
import ChatHistory from './OpenAI/ChatHistory';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import '../../styles/layout/_layout.scss';
import utils from '../../utils';
import MarketSearch from './WAP/MarketSearch';
import AppSearch from './Nav/AppSearch';

function AlchemyApp({
  preview = false,
  tenantSetting,
  appType,
  content,
  displayFieldName,
  tooltip,
  getTooltipContentByKey,
  appClass='alc-app'
}) {
  const [activeEditor, setActiveEditor] = useState('Products');
  const [editorOptions, setEditorOptions] = useState([]);
  const [disableProductEdits, setDisableProductEdits] = useState(true);

  const [tenantId, setTenantId] = useState(null);
  const [visibleConfidentialities, setVisibleConfidentialities] = useState([]);
  const [editableConfidentialities, setEditableConfidentialities] = useState([]);
  const [showMSTFields, setShowMSTFields] = useState(false);
  const [apiKey, setApiKey] = useState();
  const [loading, setLoading] = useState(true);

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const userName = accounts[0]?.username;

  // Fetch tenant and editor details
  useEffect(() => {
    if (!tenantSetting || !tenantSetting.tenant || !tenantSetting.tenant.fields || !tenantSetting.tenant.fields.apps) {
      return;
    }

    setTenantId(tenantSetting.tenant.fields.aDGroupID);

    // Get the available list of Editors based on tenant settings
    const alchemyAppList = tenantSetting.tenant.fields.apps.filter(app => app.fields.apptype === 'glbl_hlth_alchemy');
    if (alchemyAppList.length === 0 || !alchemyAppList[0].fields || !alchemyAppList[0].fields.alchemyEditors) {
      return;
    }
    const alchemyApp = alchemyAppList[0];
    const editorsString = alchemyApp.fields.alchemyEditors;
    const editors = editorsString.split(',');

    setEditorOptions(editors.map(o => ({ label: o, value: o })));
    setActiveEditor(editors[0]);

    // Disable product edits based on tenant setting
    setDisableProductEdits(alchemyApp.fields.disableProductEdits === 'false' ? false : true);

    // Set visible and editable confidentialities
    const visConfs = tenantSetting.tenant.fields.visibleConfidentialities?.map(vc => vc.fields.confidentiality) || [];
    setVisibleConfidentialities(visConfs);

    const editConfs = tenantSetting.tenant.fields.editableConfidentialities?.map(vc => vc.fields.confidentiality) || [];
    setEditableConfidentialities(editConfs);

    // Show MST fields if enabled for the tenant
    setShowMSTFields(tenantSetting.tenant.fields.showMSTFields === 'true');
  }, [tenantSetting]);

  // Fetch API key
  useEffect(() => {
    const getAccessToken = async (instance, account) => {
      const request = { scopes: ['User.Read'] };
      try {
        const token = await instance.acquireTokenSilent({ ...request, account });
        return token;
      } catch (error) {
        return null;
      }
    };

    (async () => {
      setLoading(true);
      try {
        const accToken = await getAccessToken(instance, accounts[0]);
        if (process.env.REACT_APP_ENV === 'PROD') {
          utils.trackAppView(appType, tenantId, accToken.accessToken);
        }

        const response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {
          headers: {
            Authorization: 'Bearer ' + accToken.accessToken
          }
        });
        const data = await response.json();
        setApiKey(data.value);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [isAuthenticated, accounts, instance, appType, tenantId]);

  const getAppForAlchemy = tenant => {
    if (!tenant) return { fields: {} };
    return tenant.tenant.fields.apps.find(app => app.fields.apptype === 'glbl_hlth_alchemy') || { fields: {} };
  };

  const tenantEyesLevel = tenantSetting.tenant.fields.fiveEyesLevel;

  return !loading ? (
    !preview ? (
      <div className='alc-app'>
        {/* TODO: Add Go Back button */}
        <h1 className='h1 m-b-start-08 m-b-end-08'>
          {getAppForAlchemy(tenantSetting).fields.appAlternateTitle || 'Global Health Alchemy'}
        </h1>

        {/* Tabs at the top */}
        <Tab.Container activeKey={activeEditor} onSelect={setActiveEditor}>
          <Nav variant='tabs' className='mb-3'>
            {editorOptions.map(editor => (
              <Nav.Item key={editor.value}>
                <Nav.Link eventKey={editor.value}>{editor.label}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey='Products'>
              <ProductSearch
                apiKey={apiKey}
                disableProductEdits={disableProductEdits}
                tenantId={tenantId}
                tenantSetting={tenantSetting}
                visibleConfidentialities={visibleConfidentialities}
                editableConfidentialities={editableConfidentialities}
                showMSTFields={showMSTFields}
                userName={userName}
                editor={activeEditor}
                appClass={appClass}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='Drug Product'>
              <TxSearch apiKey={apiKey} editor={activeEditor} appClass={appClass} />
            </Tab.Pane>
            <Tab.Pane eventKey='Vaccines'>
              <VaccineSearch apiKey={apiKey} editor={activeEditor} appClass={appClass} />
            </Tab.Pane>
            <Tab.Pane eventKey='Organizations'>
              <OrganizationSearch apiKey={apiKey} editor={activeEditor} appClass={appClass} />
            </Tab.Pane>
            <Tab.Pane eventKey='Countries'>
              <CountrySearch apiKey={apiKey} userName={userName} editor={activeEditor} appClass={appClass} />
            </Tab.Pane>
            <Tab.Pane eventKey='Synonyms'>
              <EnumSearch apiKey={apiKey} editor={activeEditor} appClass={appClass} />
            </Tab.Pane>
            <Tab.Pane eventKey='Demand'>
              <DemandSearch apiKey={apiKey} editor={activeEditor} tenantEyesLevel={tenantEyesLevel} appClass={appClass} />
            </Tab.Pane>
            <Tab.Pane eventKey='Price'>
              <PriceSearch
                apiKey={apiKey}
                disableProductEdits={disableProductEdits}
                tenantId={tenantId}
                visibleConfidentialities={visibleConfidentialities}
                editableConfidentialities={editableConfidentialities}
                showMSTFields={showMSTFields}
                userName={userName}
                editor={activeEditor}
                appClass={appClass}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='CE'>
              <ActivitySearch
                apiKey={apiKey}
                userName={userName}
                tooltip={tooltip}
                getTooltipContentByKey={getTooltipContentByKey}
                editor={activeEditor}
                appClass={appClass}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='Amadya'>
              <Amadya
                apiKey={apiKey}
                userName={userName}
                appClass={appClass}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='GVMM Assistant'>
              <GVMMAssistant
                apiKey={apiKey}
                userName={userName}
                appClass={appClass}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='Chat History'>
              <ChatHistory
                apiKey={apiKey}
                userName={userName}
                appClass={appClass}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='WAP'>
              <MarketSearch 
                apiKey={apiKey} 
                appClass={appClass} 
                userName={userName}
                editor={activeEditor}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='Nav'>
              <AppSearch
                apiKey={apiKey} 
                appClass={appClass} 
                userName={userName}
                editor={activeEditor}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    ) : (
      <div className='preview-img-div'>
        <img className='preview-card-img' src={getAppForAlchemy(tenantSetting).fields.previewImage?.url} alt='Preview' />
      </div>
    )
  ) : (
    <ListSkeletonLoader preview={preview} />
  );
}

export default AlchemyApp;
