import React, { useEffect} from 'react'; 
import { Button, Modal, ButtonGroup, ButtonToolbar} from 'react-bootstrap'

import '../../../styles/layout/_layout.scss'
import EnumSelector from '../EnumSelector'

function EnumDeleter({lbWord, setLbWord, apiKey, closePopup, onSubmit}) {

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit (e){
    setLbWord({...lbWord, deleted:true})
    onSubmit(e)
  }

  useEffect(()=>{
    setLbWord({...lbWord, deleted:true})
    // React 18 ready
    return () => { };
    // eslint-disable-next-line
  },[])

  function setReplacement(replacement){
    const replace_value = replacement.value
    setLbWord({...lbWord, replacement:replace_value, replacement_id: replace_value.enum_id})
  }

  function handleClose(){
    setLbWord({...lbWord, deleted:false});
    closePopup();
  }

  /*--------------------------------Manage state variables-----------------------*/

  function printLbWord(){
    console.log("lb word", lbWord)
  }
  
  return (
    <Modal show = {true} onHide={closePopup}>
      <Modal.Header>
        <Modal.Title>LB Word Deleter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="error"><strong>This word will be marked deleted:</strong></div>
          <div className='input-text m-b-start-03'>Word</div>
          <input
            className='form-ctrl'
            type='text'
            required
            disabled
            value = {lbWord.enum}
          />
          <div className='input-text m-b-start-03'>Replace With:</div>
          <EnumSelector isClearable = {true} 
            apiKey={apiKey}
            enum_type={lbWord.enum_type} 
            onChange = {(e) => setReplacement(e)}/>
          {lbWord.replacement_id &&
            <div className='error'>WARNING: replacing words may not always work due to unique requirements. If this operation fails contact an adminstrator</div>
          }
          
            <ButtonToolbar className='editor-btn m-b-start-03'>
              <ButtonGroup>
                <Button onClick = { handleClose } className='m-i-end-03' variant = "secondary">Close</Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick = {handleSubmit} className='m-i-end-03' variant = "primary">Submit</Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick = { printLbWord } variant="primary">Check Word</Button>
              </ButtonGroup>
            </ButtonToolbar>
      </Modal.Body>
    </Modal>

  )
}

export default EnumDeleter;