import React from 'react';
import * as Icons from 'react-bootstrap-icons';

function DynamicIcon({iconName=null, className}) {

    const {[ iconName ]: Icon } = Icons;
  
    return (
        iconName ?
            <Icon className={className}></Icon>
        : null
    )
}

export default DynamicIcon