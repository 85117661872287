import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';


import PageItemEditor from './PageItemEditor';
import '../../../styles/layout/_layout.scss';

function SubNavEditor({ subNav, onChange, onDelete }) {
  const [expanded, setExpanded] = useState(false);
  
  return (
    <div className>
      <Row>
        <Col xs={3}>
          <input
            className='form-ctrl'
            type='text'
            required
            value={subNav.linkLabel}
            onChange={(e) => onChange({ ...subNav, linkLabel: e.target.value })}
          />
        </Col>
        <Col xs={5}>
          <input
            className='form-ctrl'
            type='text'
            required
            value={subNav.urlForField}
            onChange={(e) => onChange({ ...subNav, urlForField: e.target.value })}
          />
        </Col>
        <Col xs={1}>
          <input
            className='form-ctrl'
            type='text'
            required
            value={subNav.orderOfDisplay}
            onChange={(e) => onChange({ ...subNav, orderOfDisplay: e.target.value })}
          />
        </Col>
        <Col xs={1}>
          <button
            className="btn-sm-invisible-red"
            onClick={onDelete}
            aria-controls="example-collapse-text"
          >
            Delete
          </button>
        </Col>
        <Col xs={1}>
          <button
            className="btn-sm-invisible-blue"
            onClick={() => setExpanded(!expanded)}
            aria-controls="example-collapse-text"
            aria-expanded={expanded}
          >
            ...
          </button>
          

        </Col>
        
      </Row>
      {expanded &&
        <Row>
          <Col xs={11}>
            <PageItemEditor
              pageItems={subNav.pageItems}
              onChange={(pageItems) => onChange({ ...subNav, pageItems:pageItems })}
              subNavName={subNav.linkLabel}
            />
          </Col>
        </Row>
      }
    </div>
  )
}

export default SubNavEditor;