import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import SubNavPopover from './SubNavPopover';
import utils from '../../../utils';

function UrlDrivenTopNav({ 
            apppath=null, 
            navs=null, 
            fields=null, 
            sendNavItemToShow, 
            item=null,
            activeTab=null,
            subUrlVal=null,
            subItems=null,
            tenantSettings=null,
        }) {
    const [activePopover, setActivePopover] = useState(null)
    const [navToShow, setNavToShow] = useState(null)

    const togglePopover = (nav, popoverId) => {
        setActivePopover((prev) => (prev === popoverId ? null : popoverId));
    };

    useEffect(() => {
        if (apppath && tenantSettings) {
            let pathToDisplay = utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', apppath, false)
            setNavToShow(pathToDisplay);
        }
    }, [apppath, tenantSettings])

    const closePopover = () => {
        setActivePopover(null)
    }

    if(item && subItems) {
        let theSubURLVal
        if(subUrlVal) {
            theSubURLVal = subUrlVal;
        } else {
            theSubURLVal = item.fields.slug;
        }
        apppath = `${navToShow ? navToShow : apppath}/${theSubURLVal}`;
    } 

    return (
        <div className='url-driven-topnav border-bottom-light m-b-end-08' >
            {

                apppath && navs && navs.length > 0 ? (
                    navs.length === 1 ? (
                        // Handle single navigation item - top level only
                        navs[0].subNav.map((navItem, index) => (
                            <LinkContainer to={`/${navToShow ? !navToShow.includes(subUrlVal) && subUrlVal ?  `${navToShow}/${subUrlVal}`: navToShow : apppath.includes(subUrlVal) ? apppath : `${apppath}/${subUrlVal}`}/${navItem.urlForField}`} key={index}>
                                <Button className={activeTab && activeTab.url === navItem.urlForField ? 'tab-nav-btn active-tab' : 'tab-nav-btn'} onClick={() => sendNavItemToShow(apppath,  null)}>{navItem.linkLabel}</Button>
                            </LinkContainer>
                        ))
                    ) : (
                        // Handle multiple navigation items - sublevels
                        navs.map((navItem, index) => (
                            <SubNavPopover
                                key={index}
                                isActive={activePopover === index}
                                togglePopover={(nav) => togglePopover(nav, index)}
                                closePopover={closePopover}
                                sendNavItemToShow={sendNavItemToShow}
                                navList={navItem}
                                apppath={apppath}
                                activeTab={activeTab}
                                navItem={navItem.groupingTitle}
                                navLevelThree={
                                    fields && fields.fields && fields.fields.capabilityAnalysesbyCompany
                                        ? fields.fields.capabilityAnalysesbyCompany
                                        : null
                                }
                            />
                        ))
                    )
                ) : null
            }
        </div>
    )
}

export default UrlDrivenTopNav;