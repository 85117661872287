import React, { useState } from "react";
import CheckBoxDropDownSelector from "../MktActionsFilterBarComps/CheckBoxDropDownSelector";

function DiseaseFilterMultiple({ diseaseList, filterChangeHandler, curFilters }) {
    const [searchTerm, setSearchTerm] = useState("");

    // Extract selected diseases from curFilters
    const curDiseaseFilterItems = curFilters
        .filter((item) => item.field === "diseases")
        .flatMap((item) => Array.isArray(item.value) ? item.value : [item.value]);

    // Filter diseases based on the search term
    const filteredDiseaseList = searchTerm
        ? diseaseList.filter((disease) =>
            disease.label.toLowerCase().startsWith(searchTerm.toLowerCase())
        )
        : diseaseList;

    // Map filtered disease labels to their selected states
    const diseasesObj = filteredDiseaseList.reduce((obj, item) => {
        obj[item.label] = curDiseaseFilterItems.includes(item.value);
        return obj;
    }, {});

    const handleSelect = (event) => {
        const selectedValue = event.target.value;
        const updatedSelection = curDiseaseFilterItems.includes(selectedValue)
            ? curDiseaseFilterItems.filter((value) => value !== selectedValue) // Remove selected
            : [...curDiseaseFilterItems, selectedValue]; // Add selected

        filterChangeHandler({
            field: "disease",
            value: updatedSelection,
        });
    };

    const labelString =
        curDiseaseFilterItems.length === 0
            ? "Select Diseases"
            : curDiseaseFilterItems.length <= 3
                ? curDiseaseFilterItems.join(", ")
                : `${curDiseaseFilterItems.length} Selected`;

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    return (
        <CheckBoxDropDownSelector
            isSearchable={true}
            handleSearchTerm={handleSearch}
            keepOpen={true}
            options={diseasesObj}
            selected={curDiseaseFilterItems}
            displayOptionHandler={handleSelect}
            labelString={labelString}
            className="toolbar-standard-dropdown"
            name="disease"
        />
    );
}

export default DiseaseFilterMultiple;
