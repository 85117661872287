import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormattedArticleList from "../News/FormattedArticleList";
import ListPagination from "../Layout/ListPagination";
import { useMsal } from "@azure/msal-react";
import utils from "../../utils";
import ircUtils from "../IRC/ircUtils";

function CountryNewsWrapper({ cntryName }) {
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [curPage, setCurPage] = useState(1);
    const paginationsize = 25;
    const history = useHistory();
    const { instance, accounts } = useMsal();

    const getAPIKey = async () => {
        const token = await utils.getAccessToken(instance, accounts[0], { scopes: ["User.Read"] });
        const response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {
            headers: { Authorization: `Bearer ${token.accessToken}` },
        });
        const { value } = await response.json();
        return value;
    };

    const fetchArticles = async () => {
        try {
            setLoading(true);
            const apiKey = await getAPIKey();
            const searchObj = {
                engine: "ce-articles",
                size: 25,
                filters: {countries: cntryName },
                page: 1,
                sort_order: "DATE_DESC",
            };

            const response = await utils.db_api_post("lb_search", apiKey, searchObj);

            if (response.results) {
                setFilteredArticles(response.results);
            } else {
                console.error("No articles found.");
            }
        } catch (error) {
            console.error("Error fetching articles:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const initializeArticles = async () => {
            await fetchArticles();
        };
        initializeArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntryName]);

    const handlePageChange = (pageNum) => {
        setCurPage(pageNum);
    };

    const handleBadgeClick = (tag) => {
        const params = new URLSearchParams();
        params.set("country", cntryName);
        if (tag.field === "country") params.set("country", tag.value);
        if (tag.field === "disease") params.set("disease", tag.value);
        history.push(`/ce_news?${params.toString()}`);
    };

    const startIdx = (curPage - 1) * paginationsize;
    const paginatedArticles = filteredArticles.slice(startIdx, startIdx + paginationsize);

    return (
        <div className="company-field-display">
            <h4>News</h4>
            <p>
                Campaign news stories from {cntryName}. Published monthly by Linksbridge.
                Subscribe to the Health Campaigns News Roundup{" "}
                <a
                    href="https://share.hsforms.com/1PL1zDURDTViPLlDIcqvz3w54nwk"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>
                .
            </p>
            {loading ? (
                <div>Loading articles...</div>
            ) : (
                <>
                    <FormattedArticleList
                        list={ircUtils.formatForNewsList(paginatedArticles)}
                        flatList={true}
                        engineName="ce-articles"
                        handleBadgeClick={handleBadgeClick}
                        showTags={false}
                        showBadges={true}
                        useStandardNewsLink={false}
                    />
                    {paginationsize > 0 && filteredArticles.length > paginationsize && (
                        <ListPagination
                            articlesPerPage={paginationsize}
                            totalArticles={filteredArticles.length}
                            paginate={handlePageChange}
                            curPage={curPage}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default CountryNewsWrapper;
