import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TagDisplay from '../TagDisplay';

import utils from '../../utils';

function ArticleItem({ article, handleBadgeClick, showBadges=null, showTags=null }) {




    return (
        <div className="article-display">
            <Row>
                <Col>
                    <h1 className="article-title m-b-start-04 m-b-end-04">{article.title}</h1>
                </Col>
            </Row>

            <Row className="article-meta m-b-end-04">
                <Col className="meta-info d-flex align-items-center">
                    <span className="subtitle-lg secondary-link">
                        {utils.getDateString(article.datepublished, 'long')}
                    </span>
                    {article.author && (
                        <span className="subtitle-lg secondary-link m-i-start-04">
                            {article.author}
                        </span>
                    )}
                </Col>
            </Row>

            <Row className="article-body">
                <Col className="body-col">
                    <div
                        className="body-text"
                        dangerouslySetInnerHTML={utils.getHTML(article.body)}
                    />

                    {article.links && (
                        <div
                            className="secondary-link-plain"
                            dangerouslySetInnerHTML={utils.getHTML(
                                utils.addTargetToLinksInHtml(utils.removeURLPlaceholder(article.links, 'news'))
                            )}
                        />
                    )}
                     {/* Show TagDisplay if showTags is true (News) - showTags expects an object*/}
                    {showTags && article.tags &&  
                        <TagDisplay
                            tagList={article.tags}
                            flatList={false}
                        />}

                    {/* Show Badges if showBadges is true (CE News) - showBadges expects a string*/}
                    {showBadges && article.tags && (
                        <div className="badge-container m-b-start-02">
                            {article.tags.map((tag, idx) => (
                                <Badge
                                    key={idx}
                                    className="tag-btn tertiary-button border-radius-sm"
                                    as={Link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleBadgeClick(tag);
                                    }}
                                    to={`/ce_news/?tag=${encodeURIComponent(tag)}`}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {typeof tag === 'string' ? tag : tag.fields?.tagtext || tag}
                                </Badge>
                            ))}
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );


}

export default ArticleItem