import React from 'react';
import { Row, Col } from 'react-bootstrap';
import TenantAppBox from './TenantAppBox';

// import '../styles/main.scss';

function AppsCollection({ tenant, className, token, showNav, windowSize, showFakes=false }) {

    if(showFakes) { 
        // replace normal apps with a full suite of lorem ipsums
        tenant.tenant.fields.apps = Array(9).fill().map((v,i)=> {
            return (
                {
                    "fields": {
                        "apptype": "",
                        "appname": "Lorem Ipsum",
                        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus ut nunc tincidunt ultricies. Integer nec justo eu justo ultricies posuere. Phasellus id nulla eu nisl scelerisque ultricies. Sed vitae scelerisque purus. Nullam nec purus ut nunc tincidunt ultricies. Integer nec justo eu justo ultricies posuere. Phasellus id nulla eu nisl scelerisque ultricies. Sed vitae scelerisque purus.",
                        "previewImage": {
                            "url": "https://picsum.photos/200"
                        },
                        "appurl": "https://www.google.com",
                        "ctatext": "Learn More"
                    }
            }); 
        });
        
    }

    return (
        token && tenant.tenant.fields.apps ?
            <Row className={"apps-list-container " + className ? className : ''}>
                {
                    tenant.tenant.fields.apps.map((thisApp, idx) => {
                        return (
                            <Col className="app-card-col" >
                                <TenantAppBox
                                    key={idx}
                                    tenantApp={thisApp}
                                    tenant={tenant}
                                    className=""
                                    preview={true}
                                    token={token} />
                            </Col>
                        )
                    })
                }
            </Row>
            : null
    )
}

export default AppsCollection
