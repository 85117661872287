import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import utils from "../../utils";
import ircUtils from "../IRC/ircUtils";
import constants from "../../constants";

import BreadCrumbBar from "../Layout/Nav/BreadCrumbBar";
import CENewsToElasticAdapter from "./CENewsToElasticAdapter";
import CEFilterBar from "./CEFilterBar";
import PublicationsList from "./PublicationsList";
import ListPagination from "../Layout/ListPagination";



function CENewsApp({ tenantSetting }) {
    const [diseaseList, setDiseaseList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [filters, setFilters] = useState([]);
    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [filtersChanged, setFiltersChanged] = useState(false);
    const [loading, setLoading] = useState(false);

    const [curPage, setCurPage] = useState(1);
    const paginationsize = 25;

    const history = useHistory();
    const { instance, accounts } = useMsal();

    const tenantID = utils.getForceTenantValue() || tenantSetting?.tenant?.fields?.aDGroupID;

    if (tenantID && !utils.getForceTenantValue()) {
        utils.setForceTenantValue(tenantID);
    }

    const getAPIKey = async () => {
        const token = await utils.getAccessToken(instance, accounts[0], { scopes: ["User.Read"] });
        const response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {
            headers: { Authorization: `Bearer ${token.accessToken}` },
        });
        const { value } = await response.json();
        return value;
    };

    const fetchAllArticles = async () => {
        try {
            const apiKey = await getAPIKey();
            const searchObj = {
                engine: "ce-articles",
                size: 10000,
                page: 1,
                filters: {},
                sort_order: "DATE_DESC",
                tenant_id: tenantID,
            };

            const response = await utils.db_api_post("lb_search", apiKey, searchObj);

            if (response.results) {
                setArticles(response.results);
                setFilteredArticles(response.results);
            } else {
                console.error("No articles found in response:", response);
            }
        } catch (error) {
            console.error("Error fetching articles:", error);
        }
    };


    const fetchFilteredArticles = async (updatedFilters) => {
        try {
            const apiKey = await getAPIKey();

            const searchObj = {
                engine: "ce-articles",
                size: 10000,
                page: 1,
                filters: {},
                sort_order: "DATE_DESC",
                tenant_id: tenantID,
            };

            updatedFilters.forEach(filter => {
                let filterKey = filter.field === "country" ? "countries" :
                    filter.field === "disease" ? "diseases" :
                        filter.field === "integration" ? "integration" :
                            filter.field === "year" ? "year" : filter.field;

                if (Array.isArray(filter.value) && filter.value.length > 0) {
                    searchObj.filters[filterKey] = filter.value.map(d => decodeURIComponent(d.trim()));
                } else if (typeof filter.value === "string" || typeof filter.value === "boolean") {
                    searchObj.filters[filterKey] = filter.value; // boolean for integration
                }
            });
            if (Object.keys(searchObj.filters).length === 0) {
                fetchAllArticles();
                return;
            }
            const response = await utils.db_api_post("lb_search", apiKey, searchObj);
            if (!response) {
                return;
            }
            if (response.results) {
                setArticles(response.results);
                setFilteredArticles(response.results);
            } else {
                console.error("No articles found in response:", response);
            }
        } catch (error) {
            console.error("Error fetching filtered articles:", error);
        }
    };


    // Country and Disease options for the filter bar
    const fetchCountriesAndDiseases = async () => {
        try {
            const apiKey = await getAPIKey();
            const [countries, diseases] = await Promise.all([
                utils.getData("get_ce_countries", apiKey, ""),
                utils.getData("get_ce_domains", apiKey, ""),
            ]);

            setCountryList(
                countries.filter((c) => c.trim() !== "").map((c) => ({ label: c, value: c }))
            );
            setDiseaseList(
                diseases.filter((d) => d.trim() !== "").map((d) => ({ label: d, value: d }))
            );
        } catch (error) {
            console.error("Error fetching countries or diseases:", error);
        }
    };


    // Year options for the filter bar
    const extractUniqueYears = (allArticles) => {
        return [
            ...new Set(allArticles.map((article) => article.fields?.year).filter(Boolean)),
        ].map((year) => ({ label: year, value: year }));
    };

    useEffect(() => {
        if (yearList.length === 0 && articles.length > 0) {
            setYearList(extractUniqueYears(articles)); // Only set once
        }
    }, [articles]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        const initializeArticles = async () => {
            setLoading(true);
            await fetchAllArticles();
            setLoading(false);
        };
        const initializeFilters = async () => {
            await fetchCountriesAndDiseases();
        };
        initializeArticles();
        initializeFilters();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        // Handle URL changes
        const handleLocationChange = (location) => {
            const params = new URLSearchParams(location.search);
            const tag = params.get("tag");
            if (tag) {
                if (!diseaseList.length || !countryList.length) {
                    return;
                }
                const isDisease = diseaseList.some((d) => d.value.toLowerCase() === tag.toLowerCase());
                const isCountry = countryList.some((c) => c.value.toLowerCase() === tag.toLowerCase());
                let filterField = isDisease ? "diseases" : isCountry ? "countries" : null;
                if (!filterField) {
                    return;
                }
                setFilters([{ field: filterField, value: [tag] }]);
                setFiltersChanged(true);
            }
        };
        // listen for URL changes
        const unlisten = history.listen(handleLocationChange);
        handleLocationChange(history.location); // run once in case of direct navigation
        return () => unlisten(); // cleanup listener
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diseaseList, countryList]); // runs again when lists update


    // Separate useEffect to fetch articles when filters update
    useEffect(() => {
        if (filtersChanged && filters.length > 0) {
            fetchFilteredArticles(filters);
            setFiltersChanged(false); // reset flag
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersChanged, filters]); // trigger only when filters update



    // Filter change handler
    const filterChanged = (newFilter) => {
        setFilters((prevFilters) => {
            const mappedField = newFilter.field === "country" ? "countries" :
                newFilter.field === "disease" ? "diseases" :
                    newFilter.field === "year" ? "year" :
                        newFilter.field === "integration" ? "integration" : newFilter.field;
            let updatedFilters = prevFilters.filter((f) => f.field !== mappedField);
            // Integration checkboxes might be `true`, `false`, or `null`
            if (newFilter.value !== null && newFilter.value !== undefined) {
                updatedFilters.push({ field: mappedField, value: newFilter.value });
            }
            updateQueryParams(updatedFilters);
            setFiltersChanged(true);
            return updatedFilters;
        });
    };


    // Query Parameters
    const updateQueryParams = (filters) => {
        const params = new URLSearchParams();
        const groupedFilters = {};
        // Group values by field
        filters.forEach(({ field, value }) => {
            if (!groupedFilters[field]) {
                groupedFilters[field] = [];
            }

            if (Array.isArray(value) && value.length > 0) {
                groupedFilters[field] = [...groupedFilters[field], ...value.map((v) => v.trim())];
            } else if (typeof value === "string" || typeof value === "number") {
                groupedFilters[field].push(value.toString().trim());
            }
        });
        // Convert grouped filters into query parameters
        Object.entries(groupedFilters).forEach(([field, values]) => {
            if (values.length > 0) {
                let queryField = field === "country" ? "countries" :
                    field === "disease" ? "diseases" : field;
                params.append(queryField, values.join(","));
            } else {
                params.delete(field);
            }
        });
        history.push({ search: params.toString() });
    };



    // handle Badge click - use badges, not TagDisplay
    const handleBadgeClick = (tag) => {
        // Determine if the tag matches a disease or country
        const isDisease = diseaseList.some((d) => d.value.toLowerCase() === tag.toLowerCase());
        const isCountry = countryList.some((c) => c.value.toLowerCase() === tag.toLowerCase());
        // Determine the field
        const filterField = isDisease ? "diseases" : isCountry ? "countries" : null;
        if (!filterField) return;
        // Update filters
        setFilters([{ field: filterField, value: [tag] }]);
        setFiltersChanged(true);  // Ensure this is set
        // Delay URL update to ensure state change propagates
        setTimeout(() => {
            const params = new URLSearchParams();
            params.set(filterField, tag);
            history.replace({ search: params.toString() });
        }, 200); // Allow state to update before navigation
    };


    // ---- Pagination ---- //
    const handlePageChange = (pageNum) => {
        setCurPage(pageNum);
    };

    // Calculate paginated articles
    const startIdx = (curPage - 1) * paginationsize;
    const paginatedArticles = filteredArticles.slice(startIdx, startIdx + paginationsize);


    return (
        <>
            <BreadCrumbBar appName={constants.APPS_BY_APPTYPE.CE_NEWS} />
            <h1 className="h1 m-b-start-08 m-b-end-08">{constants.APPS_BY_NAME.CE_NEWS}</h1>
            <Tab.Container id="ce-news-tabs" defaultActiveKey="news">
                <Nav variant="tabs" className="mb-3">
                    <Nav.Item key="news">
                        <Nav.Link eventKey="news">News</Nav.Link>
                    </Nav.Item>
                    <Nav.Item key="publications">
                        <Nav.Link eventKey="publications">Publications</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="news">
                        <Row>
                            <Col lg={9} className="search-content">
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <CENewsToElasticAdapter
                                        loading={loading}
                                        articles={paginatedArticles}
                                        inputArray={filters}
                                        paginationsize={25}
                                        getNewsArticleTags={ircUtils.getNewsArticleTags}
                                        formatForNewsList={ircUtils.formatForNewsList}
                                        filterChanged={filterChanged}
                                        handleBadgeClick={handleBadgeClick}
                                    />
                                )}
                                {paginationsize > 0 && filteredArticles.length > paginationsize && (
                                    <ListPagination
                                        articlesPerPage={paginationsize}
                                        totalArticles={filteredArticles.length}
                                        paginate={handlePageChange}
                                        curPage={curPage}
                                    />
                                )}
                            </Col>
                            <Col lg={3} className="uni-filter-bar m-b-start">
                                <CEFilterBar
                                    onFilterChanged={filterChanged}
                                    countryList={countryList}
                                    diseaseList={diseaseList}
                                    yearList={yearList}
                                    curFilters={filters || {}}
                                />
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="publications">
                        <Row>
                            <Col lg={9}>
                                <PublicationsList titleOfList="" listName="cepublicationlist" />
                            </Col>
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
}

export default CENewsApp;

