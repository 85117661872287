import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { Row } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { useToast } from '../../components/ToastProvider';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';
import CountryHarness from '../../components/IRC/CountryHarness';
import { useParams, useHistory } from 'react-router-dom';
import BreadCrumbBar from '../../components/Layout/Nav/BreadCrumbBar';
import CountryCard from './CountryCard';




function IRCAppPage({ tenant }) {

  const { cntryISO } = useParams();
  const { addToast } = useToast();

  const history = useHistory();

  let [contentModel, setContentModel] = useState();
  let [loading, setLoading] = useState(true);
  let [ navDescr, setNavDescr ] = useState({});
  let [ countriesList, setCountriesList ] = useState([]);
  let [ selectedCountry, setSelectedCountry ] = useState(null);
  let [ selectedRegion, setSelectedRegion ] = useState(null);
  let [ filteredCountries, setFilteredCountries ] = useState([]);
  let [ apiKey, setApiKey ] = useState(null);
  let [ pickedCountry, setPickedCountry ] = useState(null);
  
  const { instance, accounts } = useMsal();

  let tenantSlug = tenant.tenant.fields.tenantslug;

  let tenID = utils.getForceTenantValue();
  let currYear = new Date().getFullYear();

  if (!tenID) {
    //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
    tenID = tenant.tenant.fields.aDGroupID || null;
    utils.setForceTenantValue(tenID);
  }

  const request = {
    scopes: ["User.Read"]
  };

  const trackCall = async (accToken, appType) => {
    //no await on purpose, we don't need to ensure that it returns - just issue it
    utils.trackAppView(appType, tenID, accToken);
  }

  const getAppTitle = (tnt, appType) => {
    let appInQuestion = tnt.tenant.fields.apps.find( (thisApp) => {
      return thisApp.fields.apptype.toLowerCase() === appType.toLowerCase();
    });

    return appInQuestion && appInQuestion.fields && appInQuestion.fields.appAlternateTitle ? appInQuestion.fields.appAlternateTitle : null;
  };

  const getContentModel = async (token) => {
    let extraParams = 'force_id=' + tenant.tenant.fields.aDGroupID;

    let addedParams = '';

    if(extraParams && extraParams.length > 0) {
        addedParams = `&${extraParams}`;
    }
    
    //use the app fields test list
    if(process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.toLowerCase() === 'dev') {
        addedParams = addedParams + '&use_test_list=true';
    }

    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-tenant-content?app=${CONSTANTS.APPS_BY_APPTYPE.IRC}&userCGs=${tenantSlug}&uid=${tenant.ourUserID}${addedParams}`, {
        headers: {
          "Authorization": "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
    });

    let jsonResp
    try{
      jsonResp = await response.json();
    } catch (error) {
      addToast({
        title: 'An error occurred',
        body: 'Cannot load needed Data - please refresh browser.',
      });
    }

    if (jsonResp.appFields) {
      setNavDescr(jsonResp.appFields);
    }

    setContentModel(jsonResp.content[0]);
  }

  const getAPIKey = async () => {
    const token = await utils.getAccessToken(instance, accounts[0], { scopes: ["User.Read"] });
    const response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {
        headers: { Authorization: `Bearer ${token.accessToken}` },
    });
    const { value } = await response.json();
    return value;
  };

  const getCountriesList = async (userType='GAVI') => {

    try {
      const res = await fetch(`${process.env.REACT_APP_DB_API_URL}get_out_countries`, {
          headers: {
            'Content-Type': 'application/json',
            "access_token": process.env.REACT_APP_DB_PUBLIC_KEY
          }
        });
      let data = await res.json();
      
      const campaign_data = await utils.getData('get_campaign_counts_by_country', apiKey);
      
      // adding an extra field to country. May break if we end up doing any upserts with this data later
      data.forEach((country) => {
        country.campaign_count = campaign_data[country.country] ? campaign_data[country.country] : 0;
      });
      
      setCountriesList(data);
      setFilteredCountries(data);
    } catch( err ) {
        addToast('Cannot load countries list - please refresh browser.', 'An error occurred', 'danger');
    }
  }

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        setApiKey(await getAPIKey())
        let accToken = utils.getAccessToken(instance, accounts[0], request);
        // only track if on prod
        if(process.env.REACT_APP_ENV === 'PROD' ) {
          trackCall(accToken, 'irc');
        }
        getContentModel(accToken);
        getCountriesList();
      })()
    } catch (error) {
      addToast('Cannot load needed Data - please refresh browser.', 'An error occurred', 'danger');;
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [apiKey]);

  useEffect(() => {
    // check url and set selected country
    if (cntryISO) {
      let country = countriesList.find((country) => country.ISO === cntryISO);
      setPickedCountry(country);
    }
    if (selectedCountry || selectedRegion) {
      let filtered = countriesList.filter((country) => {
        return (selectedCountry ? country.ISO === selectedCountry : true) && (selectedRegion ? country.who_region === selectedRegion : true)
      });
      setFilteredCountries(filtered);
    } else {
      setFilteredCountries(countriesList);
    }
  }, [selectedCountry, selectedRegion, countriesList, cntryISO]);

  const pickCountryHandler = (picked) => {
    history.push(`/${utils.findURLPath(tenant && tenant.tenant && tenant.tenant.fields && tenant.tenant.fields.apps ? tenant.tenant.fields.apps : '', "irc_app")}/` + picked.ISO)
    setPickedCountry(picked);
  }

  const findCountryNameFromISO = (isoCode, cntrList) => {
    let isoMatch = isoCode ? cntrList.filter((country) => country.ISO.toUpperCase() === isoCode.toUpperCase()) : null

    if (isoMatch && isoMatch.length > 0) {
      return isoMatch[0].country;
    } else {
      return '';
    }
  }

  const getUniqueRegions = (countryList) => {
    const regions = countryList.map((country) => country.who_region);
    return [...new Set(regions)]
  }

  return (
    !loading && contentModel && countriesList?
      <div className='irc-app'>
        <BreadCrumbBar
          appAltUrl={'country_profiles'}
          appName={'IRC'} 
          appAltTitle={getAppTitle(tenant, CONSTANTS.APPS_BY_APPTYPE.IRC)}
          displayFieldName={'iso'} 
          item={cntryISO ? { iso: findCountryNameFromISO(cntryISO, countriesList) }: null} />

        <div className={`app-page-display ${CONSTANTS.APPS_BY_APPTYPE.IRC}`}>
          <h1 className='h1 m-b-start-08 m-b-end-08'>{findCountryNameFromISO(cntryISO, countriesList) ? `${findCountryNameFromISO(cntryISO, countriesList)}` : CONSTANTS.APPS_BY_NAME.COUNTRY_PROFILES}</h1>
          {
            cntryISO ?
              <div>
                <div className='flex-container-100 m-b-start-08 m-b-end-08'>
                <div className='data-row'>
                        <p className='m-b-end label-small'>Population</p>
                        <p className='m-b-end body-medium-comfortable'>{pickedCountry?.total_population ? pickedCountry.total_population?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A"}</p>
                    </div>
                    <div className='data-row'>
                        <p className='m-b-end label-small'>GNI Per Capita (US$)</p>
                        <p className='m-b-end body-medium-comfortable'>{pickedCountry?.last_known_gni ? pickedCountry.last_known_gni.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A"}</p>
                    </div>
                    <div className='data-row'>
                        <p className='m-b-end label-small'>World Bank Status</p>
                        <p className='m-b-end body-medium-comfortable'>{pickedCountry?.wb_status ? pickedCountry.wb_status : "N/A"}</p>
                    </div>
                    <div className='data-row'>
                        <p className='m-b-end label-small'>WHO Region</p>
                        <p className='m-b-end body-medium-comfortable'>{pickedCountry?.who_region  ? pickedCountry.who_region : "N/A"}</p>
                    </div>
                    <div className='data-row'>
                        <p className='m-b-end label-small'>{`No. of Campaigns in ${currYear}`}</p>
                        <p className='m-b-end body-medium-comfortable'>{pickedCountry?.campaign_count  ? pickedCountry.campaign_count: 0}</p>
                    </div>
                </div>
                <CountryHarness 
                  navDescr={navDescr}
                  tenant={tenant} 
                  selCountry={cntryISO} 
                  fullCountryList={countriesList}
                  activeTab={'campaigns-overview'}
                  contentFromRec={contentModel} />
              </div>
            :
            <>
              <Row className='m-b-start-08 m-b-end-08'>
              <div className='flex-container-50 flex-row p-i-start-04'>
                <div className='width-45'>
                  <p className='label-large'>Country</p>
                  <Select 
                    className="select-new-basic"
                    options={countriesList.map((country) => {
                      return { value: country.ISO, label: country.country }
                    })}
                    isClearable={true}
                    onChange={(e) => {setSelectedCountry(e ? e.value : null)}}
                  ></Select>
                </div>
                <div className='width-45'>
                  <p className='label-large'>Region</p>
                  <Select 
                    className="select-new-basic"
                    options={getUniqueRegions(countriesList).map((region) => {
                      return { value: region, label: region }
                    }).sort((a, b) => a.label.localeCompare(b.label))}
                    isClearable={true}
                    onChange={(e) => {setSelectedRegion(e ? e.value : null)}}
                  ></Select>
                </div>
              </div>
              </Row>
              <Row>
                <div className='irc-country-cards'>
                  {filteredCountries.map((country, index) => {
                    return <CountryCard 
                              key={index}
                              apiKey={apiKey}
                              country={country}
                              index={index}
                              onClick={() => pickCountryHandler(country)} 
                            />
                  })}
                </div>
              </Row>
            </>
          }
        </div>
      </div>
    : <ListSkeletonLoader preview={false} />

  )
}

export default IRCAppPage;
