import React, { useState } from "react";
import CheckBoxDropDownSelector from "../MktActionsFilterBarComps/CheckBoxDropDownSelector";

function CountryFilterMultiple({ countryList, filterChangeHandler, curFilters }) {
    const [searchTerm, setSearchTerm] = useState(""); // State for managing the search term
    // Extract selected countries from curFilters
    const curCountryFilterItems = curFilters
        .filter((item) => item.field === "countries")
        .flatMap((item) => (Array.isArray(item.value) ? item.value : [item.value]));

    // Filter countries based on the search term
    const filteredCountryList = searchTerm
        ? countryList.filter((country) =>
              country.label.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : countryList;

    // Map filtered country labels to their selected states
    const countriesObj = filteredCountryList.reduce((obj, item) => {
        obj[item.label] = curCountryFilterItems.includes(item.value);
        return obj;
    }, {});


    // Handle selections
    const handleSelect = (event) => {
        const selectedValue = event.target.value;
        const updatedSelection = curCountryFilterItems.includes(selectedValue)
            ? curCountryFilterItems.filter((value) => value !== selectedValue) // Remove selected
            : [...curCountryFilterItems, selectedValue]; // Add selected

        filterChangeHandler({
            field: "countries",
            value: updatedSelection,
        });
    };

    const handleSearch = (term) => {
        setSearchTerm(term); 
    }
    
    const labelString =
        curCountryFilterItems.length === 0
            ? "Select Countries"
            : curCountryFilterItems.length <= 3
            ? curCountryFilterItems.join(", ") 
            : `${curCountryFilterItems.length} Countries Selected`; 

    return (
        <CheckBoxDropDownSelector
            isSearchable={true}
            handleSearchTerm={handleSearch}
            keepOpen={true}
            options={countriesObj}
            selected={curCountryFilterItems}
            displayOptionHandler={handleSelect}
            labelString={labelString}
            selectAll={false}
            className="toolbar-standard-dropdown"
            name="country"
        />
    );
}

export default CountryFilterMultiple;
