import utils from "../../../utils";

function isValidURL(inputURL) {
  try {
    new URL(inputURL);
    return true;
  } catch (error) {
    return false;
  }
};

const checkForErrors = (app) => {
    let errors = []
    // Check basic info errors
    if (!app.key){
      errors.push("Please provide an app type")
    }

    for (let i=0; i<app.appFields.length; i++){ {
        let group = app.appFields[i];
        if (!group.groupTitle) {
            errors.push(`Please provide a group title for the ${utils.makeOrdinal(i+1)} group `)
        }
        for (let j=0; j<group.subNav.length; j++){
            let subNav = group.subNav[j];
            if (!Number.isInteger(subNav.orderOfDisplay)) {
                errors.push(`Please provide a number for the order of display for the ${utils.makeOrdinal(i+1)} group and ${utils.makeOrdinal(j+1)} subnav`)
            }
            if (!isValidURL(`https://www.website.com/${subNav.url}`)) {
                errors.push(`Please provide a valid URL for the ${utils.makeOrdinal(i+1)} group and ${utils.makeOrdinal(j+1)} subnav`)
            }
            for (let k=0; k<subNav.pageItems.length; k++){
                let pageItem = subNav.pageItems[k];
                if (!pageItem.fieldAPIName ) {
                  errors.push(`Please provide a field API name for the ${utils.makeOrdinal(i+1)} group's ${utils.makeOrdinal(j+1)} subnav's ${utils.makeOrdinal(k+1)} page item`)
                }
                for (let l=0; l<pageItem.overrides.length; l++){
                    let override = pageItem.overrides[l];
                    if (!override.conditionFieldName) {
                        errors.push(`Please provide a Condition Field for the ${utils.makeOrdinal(i+1)} group's ${utils.makeOrdinal(j+1)} subnav's ${utils.makeOrdinal(k+1)} page item's ${utils.makeOrdinal(l+1)} override`)
                    }
                    if (!override.conditionOperator) {
                      errors.push(`Please provide a Condition Operator for the ${utils.makeOrdinal(i+1)} group's ${utils.makeOrdinal(j+1)} subnav's ${utils.makeOrdinal(k+1)} page item's ${utils.makeOrdinal(l+1)} override`)
                    }
                    if (!override.conditionValue) {
                      errors.push(`Please provide a Condition Value for the ${utils.makeOrdinal(i+1)} group's ${utils.makeOrdinal(j+1)} subnav's ${utils.makeOrdinal(k+1)} page item's ${utils.makeOrdinal(l+1)} override`)
                    }
                    for (let m = 0; m < override.pBI_Override_Filters.length; m++){
                      let filter = override.pBI_Override_Filters[m];
                      if (!filter.filterValues) {
                        errors.push(`Please provide Filter Values for the ${utils.makeOrdinal(i+1)} group's ${utils.makeOrdinal(j+1)} subnav's ${utils.makeOrdinal(k+1)} page item's ${utils.makeOrdinal(l+1)} override's ${utils.makeOrdinal(m+1)} filter`)
                      }
                      if (!filter.operator) {
                        errors.push(`Please provide an operator for the ${utils.makeOrdinal(i+1)} group's ${utils.makeOrdinal(j+1)} subnav's ${utils.makeOrdinal(k+1)} page item's ${utils.makeOrdinal(l+1)} override's ${utils.makeOrdinal(m+1)} filter`)
                      }
                      if (!filter.targetColumn) {
                        errors.push(`Please provide a column for the ${utils.makeOrdinal(i+1)} group's ${utils.makeOrdinal(j+1)} subnav's ${utils.makeOrdinal(k+1)} page item's ${utils.makeOrdinal(l+1)} override's ${utils.makeOrdinal(m+1)} filter`)
                      }
                      if (!filter.targetTable) {
                        errors.push(`Please provide a table for the ${utils.makeOrdinal(i+1)} group's ${utils.makeOrdinal(j+1)} subnav's ${utils.makeOrdinal(k+1)} page item's ${utils.makeOrdinal(l+1)} override's ${utils.makeOrdinal(m+1)} filter`)
                      }
                    }
                }
            }
        }
    }
    return errors
  }
}
  export default checkForErrors;