import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap'
import OverrideEditor from './OverrideEditor';
import Select from 'react-select';


import SimpleSelect from '../SimpleSelect';
import PBIFilterEditor from './PBIFilterEditor';
import '../../../styles/layout/_layout.scss'

const securityOptions = ['1', '2', '3', '4', '5', '6'];

// This probably isn't ideal, but this doesn't follow the normal pattern for enums. For enums the field and value match.
// Here there are differences like RTF vs Rich Text.
const fieldTypeOptions = [{label:'PBI', value:'PBI'}, {label:'Pyramid', value:'PYRAMID'}, {label:'Rich Text', value:'RTF'}];

function PageItemEditor({ pageItem, onChange, close, onDelete }) {
  const [ fieldType, setFieldType ] = useState(null);
  
  useEffect(()=>{
    if (!pageItem?.fieldType){
      return
    }
    const temp = fieldTypeOptions.find((f) => f.value === pageItem.fieldType);
    setFieldType(temp);

  }, [pageItem?.fieldType])

  function handleFieldTypeChange(e){
    if(e.value==='PBI'){
      onChange({...pageItem, fieldType: e.value});
    }else{
      onChange({...pageItem, fieldType: e.value, pBIDatasetID:'', pBIReportID:''});
    }
  }
  
  return (
    <Modal size='xl' show={true} className={'alc-app'}>
      <Modal.Body>
        <Row>
          
          <Col>
            <button
              className="btn-sm-invisible-dark"
              onClick={close}
              aria-controls="example-collapse-text"
            >
              {'< Back'}
            </button>
          </Col>
          <Col xs={9}/>
          <Col>
            <div></div>
            <button className="btn-sm-invisible-red" onClick={onDelete}>Delete</button>
          </Col>
        </Row>

        {/*                 ROW 1          */}
        <Row>
          <Col>
            <div className='input-text'>Item Title</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.itemTitle}
              onChange={(e) => onChange({ ...pageItem, itemTitle: e.target.value })}
            />
          </Col>
          
          <Col>
            <div className='input-text'>Height</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.customPyramidHeight}
              onChange={(e) => onChange({ ...pageItem, customPyramidHeight: e.target.value })}
            />
          </Col>
          <Col>
            <div className='input-text'>Field API Name</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.fieldAPIName}
              onChange={(e) => onChange({ ...pageItem, fieldAPIName: e.target.value })}
            />
          </Col>
          
          
        </Row>

        {/*                 ROW 2          */}
        <Row>
          <Col>
            <div className='input-text'>Master Content ID</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.masterContentID}
              onChange={(e) => onChange({ ...pageItem, masterContentID: e.target.value })}
            />
          </Col>
          <Col>
            <div className='input-text'>Width</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.customPyramidWidth}
              onChange={(e) => onChange({ ...pageItem, customPyramidWidth: e.target.value })}
            />
          </Col>
          <Col>
            <div className='input-text'>Field Type</div>
            <Select
              options={fieldTypeOptions}
              value={fieldType}
              onChange={handleFieldTypeChange}
            />
          </Col>
          
        </Row>
        {/*                 ROW 3          */}
        <Row>
          
          <Col>
            <div className='input-text'>Pyramid Filter String</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.pyramidFilterString}
              onChange={(e) => onChange({ ...pageItem, pyramidFilterString: e.target.value })}
            />
          </Col>
          <Col>
            <div className='input-text'>PBI Dataset ID *</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.pBIDatasetID}
              onChange={(e) => onChange({ ...pageItem, pBIDatasetID: e.target.value })}
              disabled = {pageItem.fieldType !== 'PBI'}
            />
          </Col>
          <Col>
            <div className='input-text'>PBI Report ID *</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.pBIReportID}
              onChange={(e) => onChange({ ...pageItem, pBIReportID: e.target.value })}
              disabled = {pageItem.fieldType !== 'PBI'}
            />
          </Col>
        </Row>
        {/*                 ROW 4          */}
        <Row>
          <Col>
            <div className='input-text'>Display Order</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.orderDisplayed}
              onChange={(e) => onChange({ ...pageItem, orderDisplayed: e.target.value })}
            />
          </Col>
          <Col>
            <div className='input-text'>PBI Viz Type</div>
            <input
              className='form-ctrl'
              type='text'
              required
              value={pageItem.pBIVizType}
              onChange={(e) => onChange({ ...pageItem, pBIVizType: e.target.value })}
            />
          </Col>
          <Col>
            <div className='input-text'>Security Level</div>
            <SimpleSelect
              options={securityOptions}
              value={pageItem.securityLevel}
              onChange = {(e) => onChange({ ...pageItem, securityLevel: e})}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className='nav-card-with-margin'>Overrides</h3>
            <div className='input-text'>Describe what override is here...</div>
            <OverrideEditor
              overrides={pageItem.overrides}
              onChange={(overrides) => onChange({ ...pageItem, overrides: overrides })}
              pageItemName={pageItem.fieldAPIName}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className='nav-card-with-margin'>PBI Filters</h3>
            <div className='input-text'>Describe what PBI filter is here...</div>
            <PBIFilterEditor
              pBIFilter={pageItem.pBIFilter}
              onChange={(pbf) => onChange({ ...pageItem, pBIFilter: pbf })}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
export default PageItemEditor;