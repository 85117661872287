import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {useToast} from '../../ToastProvider'

import CountryEditor from './CountryEditor';
import ImportWithTemplate from '../ImportWithTemplate';
import utils from '../../../utils';
import '../../../styles/layout/_layout.scss';
import StatefulSearchTable from '../StatefulSearchTable';
import TransferButton from '../TransferButton';


const sortOptions = [
  {title:'Country', field:'country'},
  {title:'UNICEF Region', field:'UNICEF_region'}
]

// Title is the column title, displayField is what's shown in the table, filterField is the
// field used for filtering, isMulti enables or disables multi selection.
const metaData = [
  {title:'Country Name', dispField:'country', filterField:'country',isMulti:true},
  {title:'WHO Region', dispField:'WHO_region', filterField:'WHO_region'},
  {title:'UNICEF Region', dispField:'UNICEF_region', filterField:'UNICEF_region'}
]


// Used for the import button.
const importColumns = [
  {xlCol:'ISO', apiCol:'ISO', type:'string', mandatory:true},
  {xlCol:'Country', apiCol:'country', type:'string', mandatory:false},
  {xlCol:'year', apiCol:'year', type:'number', mandatory:true},
  {xlCol:'field', apiCol: 'field', type:'string', mandatory:true},
  {xlCol:'value', apiCol:'value', type:'string', mandatory:true}
]

function CountrySearch({apiKey, editor, appClass, userSettings, userName}) {

  const {addToast} = useToast();
  // Local representation with data augmentations
  const [countries, setCountries] = useState([]);
  // For communication about transfer progress
  const [xferMessage, setXferMessage] = useState();
  // Product that gets edited when double clicked
  const [ISO, setISO] = useState(null);
  // A toggle for showing the edit product modal
  const [showEditCountry, setShowEditCountry] = useState(false);


  async function fetchData(setCountries, apiKey, addToast) {
    setCountries([]); 
    try {
      let result = await utils.getData("get_countries", apiKey);
      
      if (typeof result === 'string') {
        addToast({
          title: 'Failed to load data. Error code 6-a',
          body: result
        });
        return;
      }
  
      setCountries(result);
    } catch (error) {
      addToast({
        title: 'Failed to load data. Error code 6-b',
        body: error.message || 'Failed to load data. Error code 6-b'
      });
  }
  
  }
  




  // Call the API to get the data only when Countries tab is active or when editor is closed (showEditCountry = false)
  useEffect(() => {
    if (editor !== 'Countries' || showEditCountry) return; 
    fetchData(setCountries, apiKey, addToast);
  }, [editor, showEditCountry, apiKey, addToast]);
  

  function toggleShowEditCountry() {
    setShowEditCountry(!showEditCountry)
  };

  function createNew() {
    toggleShowEditCountry()
    setISO(null)
  }

  function update(country) {
    toggleShowEditCountry()
    setISO(country.ISO)
  }

  // settingsContent
  const settingsContent = {

    triggerXfer: async () => {
      setXferMessage("Working on transfer");
      const result = await utils.db_api_post('transfer_country_data', apiKey, '');
      setXferMessage(result === true ? "Done" : "Error");
    },

    settingsButtons: () => (
      <>
        <TransferButton 
        userName={userName}
        apiKey={apiKey}
        disabled={false}
        endpoint={'transfer_countries_to_production'}
        module={'country'}
        environment='production'/>      
      </>
    ),
  };

    function toolbarButtons(){
    return <>
      <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
    </>
  }

  function secondRow(){
    return <>
      <div>{xferMessage}</div>
      <ImportWithTemplate
        apiKey={apiKey}
        importColumns={importColumns}
        contentId={25113}
        endpoint="import_country_info"
      />
    </>
  }

  return (
    <div>
      {showEditCountry ?
        <CountryEditor
          apiKey={apiKey}
          id={ISO !== null ? ISO : null}
          closePopup={toggleShowEditCountry}
        />
        : null
      }
      <StatefulSearchTable
        //buttons={getButtons()}
        data={countries}
        rowsPerPage={20}
        metaData={metaData}
        sortData={sortOptions}
        apiKey={apiKey}
        onRowClick={update}
        module='country'
        editor={editor}
        userName={userSettings}
        secondRow={secondRow()}
        settingsContent={settingsContent}
        settingsButtons={settingsContent.settingsButtons}
        toolbarButtons={toolbarButtons}
        appClass={appClass}
      />
    </div>

  )
}

export default CountrySearch;