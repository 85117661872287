import React from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';

import YearFilterMultiple from './YearFilterMultiple';
import DiseaseFilterMultiple from './DiseaseFilterMultiple';
import CountryFilterMultiple from './CountryFilterMultiple';

function CEFilterBar({ onFilterChanged, countryList, diseaseList, yearList, curFilters }) {

  
  return (
    countryList && diseaseList && yearList ?
      <>
      <Row className='extra-spacer'>
        <Col>
          <CountryFilterMultiple
            countryList={countryList}
            filterChangeHandler={onFilterChanged}
            curFilters={curFilters} />
        </Col>
      </Row>
      <Row className='extra-spacer'>
        <Col>
          <DiseaseFilterMultiple
            diseaseList={diseaseList}
            filterChangeHandler={onFilterChanged}
            curFilters={curFilters} />
        </Col>
      </Row>
      <Row className='extra-spacer'>
        <Col>
          <YearFilterMultiple
            yearList={yearList}
            filterChangeHandler={onFilterChanged}
            curFilters={curFilters} />
        </Col>
      </Row>  
      <Row>
        <Col>
          <h5 className='int-h5'>Integration</h5>
        </Col>
      </Row>  
      <Row>
          <Col>
            <div>
            <input
              type="checkbox"
              className="checkbox"
              name="non-integrated"
              checked={curFilters.some((f) => f.field === "integration" && f.value === 'false')}
              onChange={(e) =>
                onFilterChanged({ field: "integration", value: e.target.checked ? 'false' : null })
            }
            />
              <label style={{ marginInlineStart: '6px' }} for="integration-none"> Non-integrated Campaigns</label>
            </div>
        </Col>
      </Row>  
        <Row>
          <Col>
            <div>
              <input
                type="checkbox"
                className="checkbox"
                name="integrated"
                checked={curFilters.some((f) => f.field === "integration" && f.value === 'true')}
                onChange={(e) =>
                  onFilterChanged({ field: "integration", value: e.target.checked ? 'true' : null })
              }
            />
              <label style={{ marginInlineStart: '6px' }} for="integration-campaigns"> Integrated Campaigns</label>
            </div>
          </Col>
        </Row>  
      </>
      :
      <Spinner animation='border' />
  )
}

export default CEFilterBar;