import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Switch, Route, useRouteMatch, generatePath } from 'react-router-dom';

import CONSTANTS from '../../constants';
import { Redirect } from 'react-router-dom';
import PDFExportButton from './PDFExportButton';
import CountryProfileFieldWrapper from './CountryProfileFieldWrapper';
import UrlDrivenTopNav from '../Layout/Nav/UrlDrivenTopNav';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';


function CountryHarness({ navDescr, tenant, selCountry, fullCountryList, contentFromRec }) {
    //this component provides routing level for country id and passes it to children

    const [selectedCountry, setSelectedCountry] = useState();
    const [loading, setLoading] = useState(false);
    const [ navURL, setNavURL ] = useState();

    let { path } = useRouteMatch();

    let useNavs = navDescr;
    
    useEffect(()=>{
        setLoading(false)
    },[loading])
    
    
    //Keep selection parameter it is needed since other apps use it.
    const handleNavSelection = (selection=null, navURL) => {
        setLoading(true);
        setNavURL(navURL);
      }

    const getDefaultPath = (forCountryISO, endUrl) => {
        return generatePath(`${path}/:isoCode/${endUrl}`, { isoCode: forCountryISO });
    }

    const getCountryFromListByISO = (iso, cntryList) => {
        return cntryList.find((thisCntry) => {
            return thisCntry.ISO === iso;
        });
    }

    const getCountryByISO = async (iso) => {
        setLoading(true);
        setSelectedCountry(getCountryFromListByISO(selCountry, fullCountryList));

        setLoading(false);
    }

    //get country info for the selCountry
    useEffect(() => {
        //get the country info
        getCountryByISO(selCountry);
        // eslint-disable-next-line
    }, [selCountry]);


    return (
        !loading ?
      
            selectedCountry ?
                <>

                    <UrlDrivenTopNav
                        apppath={CONSTANTS.APPS_BY_APPTYPE.IRC}
                        navs={useNavs} 
                        subItems={false}
                        sendNavToState={(nav) => setNavURL(nav)}
                        sendNavItemToShow={handleNavSelection}
                        navURL={navURL}
                        item={contentFromRec}
                        subUrlVal={selCountry} 
                        activeTab={'campaigns-overview'}
                        tenantSettings={tenant}
                    />
                    <Row>
                        <Col xs={12} sm={7}></Col>
                        <Col xs={12} sm={5} className='text-end'>
                            {
                                <PDFExportButton country={selectedCountry.country} />       
                            }
                        </Col>
                    </Row>
                    <Switch>
                        <Route exact path={path}>
                            {/* if no trailing url, show the data_overview panel */}
                            <Redirect
                                to={getDefaultPath(selCountry, 'campaigns-overview')} />
                        </Route>
                        <Route path={`${path}/:infoID`}>
                            {/* else, show the fieldDisplay panel */}
                            <CountryProfileFieldWrapper
                                tenant={tenant}
                                showGenPageTitle={true}
                                cntryInfo={selectedCountry}
                                fields={contentFromRec}
                                fieldsDescr={navDescr}
                                ISO={ selCountry.ISO } />
                            
                        </Route>
                    </Switch>
                </>

            : null
        : <ListSkeletonLoader preview={false}/>
    )
}

export default CountryHarness