import React, {useEffect, useState, useCallback} from 'react'
import { Row, Col, Button } from 'react-bootstrap'

import ProductEditor from './ProductEditor';
import { useToast } from '../../ToastProvider';
import StatefulSearchTable from '../StatefulSearchTable';
import StagingAndProdTransferButtons from '../StagingAndProdTransferButtons';
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';

// True constants to this scope. They do not need to be reevaluated at each render so pull out of main
// function
const sortOptions = [
  {title:'Vaccine-Manufacturer', field:'vac_mfg'}, // << This is the default sort, maybe add param for ascending/descending
  {title:'Update Date', field: "valid_from", desc:true},
  {title:'Created Date', field: 'created_date', desc:true}
]

// Title is the column title, displayField is what's shown in the table, filterField is the
// field used for filtering, isMulti enables or disables multi selection.



function ProductSearch({apiKey, disableProductEdits, tenantId, tenantSetting, visibleConfidentialities, editableConfidentialities,showMSTFields,userName, editor, appClass}) {

  // Local representation with data augmentations
  const [products, setProducts] = useState([]);
  // Product that gets edited when double clicked
  const [productId, setProductId] = useState(null);
  // Filtered products, needed for download button
  const [filteredIDs, setFilteredIDs] = useState([]);
  // A toggle for showing the edit product modal
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const { addToast } = useToast();
  const [ getFromLocalStore, setGetFromLocalStore] = useState(false);

  const handleFilteredIDsChange = useCallback((ids) => {
    setFilteredIDs(ids);
  }, []);


  useEffect(() => {
    const baseMetaData = [
      {title:'Vaccine', dispField:'vaccine', filterField:'vaccine',isMulti:true},
      {title:'Vaccine Subtype', dispField:'vaccine_subtype', filterField:'vaccine_subtype',isMulti:true},
      {title:'Antigens', dispField:'antigens_str', filterField:'antigens',isMulti:true},
      {title:'Form', dispField:'form'},
      {title:'Company', dispField:'company_str', filterField:'company', isMulti:true},
      {title:'Product Name', dispField:'product_name', filterField:'product_name',isMulti:true},
      {title:'Phase', dispField:'development_phase', filterField:'development_phase',isMulti:true},
      {title:'Status', dispField:'lb_status', filterField:'lb_status', isMulti:true},
      {title:'Has Capacity', dispField:'has_capacity', filterField:'has_capacity'},
      {title:'Updated By', dispField:'updated_by', filterField:'updated_by',isMulti:true},
      {title:'Updated Date', dispField:'valid_from_str'},
      {title:'Created Date', dispField:'created_date_str'}
    ]
    if (showMSTFields){
      const gaviAdditions = [
        {title: 'Level of Benefit', dispField:'level_of_benefit', filterField:'level_of_benefit', isMulti:true},
        {title: 'Support Required', dispField:'support_required', filterField:'support_required', isMulti:true},
        {title: 'Prioritization Category', dispField:'prioritization_category', filterField:'prioritization_category', isMulti:true},
      ]
      setMetaData([...baseMetaData, ...gaviAdditions])
    } else {
      setMetaData(baseMetaData)
    }
  }, [showMSTFields])

  // Automatic trigger to refetch the data whenever the user is done editing the data.
  // This is automatically triggered by switching the t/f variable for showing the editing modal to false
  useEffect(() => {
    if(showEditProduct === false){
      async function fetchData() {
        // Get raw api response
        let fullResponse = await utils.getData("get_view_products", apiKey,`?tenant_id=${tenantId}`)
        if (typeof fullResponse === 'string'){
          addToast({
            title: 'Failed to load data. Error code 1-a',
            body: fullResponse
          })
          return;
        }
        // Now flatten complex data into strings, lists of strings, numbers, and dates.
        let augmentedResponse = fullResponse.map(prod=> ({ ...prod, 
          antigens: prod.antigens.split('|'),
          antigens_str:prod.antigens.split('|').join(','),
          company: prod.organizations.split('|'),
          company_str: prod.organizations.split('|').join(','),
          vac_mfg:prod.vaccine_subtype+prod.organizations,
          valid_from: new Date(prod.valid_from),
          valid_from_str: (new Date(prod.valid_from)).toLocaleDateString(),
          created_date: new Date(prod.created_date),
          created_date_str: (new Date(prod.created_date)).toLocaleDateString()
        }))
        setProducts(augmentedResponse)
      }
      
      fetchData()
    }
  },[showEditProduct, tenantId, apiKey, addToast]
  )

  const createNew = () => {
    setShowEditProduct(true); 
    setProductId(null); 
    setGetFromLocalStore(false);
  };

  function update(product){
    setShowEditProduct(true);
    setProductId(product.product_id);
    setGetFromLocalStore(false);
  }

  function openLocalStoreProduct(){
    setShowEditProduct(true);
    setProductId(null);
    setGetFromLocalStore(true);
  }

  function closePopup(){
    setShowEditProduct(false) 
  }

  const settingsContent = {

    handleGetCapacityClick: async () => {
      const data = await utils.getData('get_gavi_capacity', apiKey);
      utils.exportExcel(data, 'Gavi Capacity Export');
    },

    handleDownloadFilteredData: async () => {
      const requestBody = filteredIDs.map(id => id.toString()); 
      const response = await utils.db_api_post('get_filtered_products', apiKey, requestBody);
      utils.exportExcel(response, 'Product Data Export');
    },

    settingsButtons: () => (
        <>
            {disableProductEdits ? null : (
                <>
                    <StagingAndProdTransferButtons
                        userName={userName}
                        apiKey={apiKey}
                        staging_endpoint={'transfer_capacity_to_staging'}
                        prod_endpoint={'transfer_product_data'}
                        module={'products'}
                        disableRefresh={false}
                    />
                </>
            )}
            <Row>
                <Col>
                    <Button className='btn-primary bg-blue-300 m-b-start-03 m-b-end-03' onClick={settingsContent.handleGetCapacityClick}>
                        Download GAVI Capacity
                    </Button>{' '}
                </Col>
                <Col>
                    <Button className='btn-primary bg-blue-300 m-b-start-03 m-b-end-03' onClick={settingsContent.handleDownloadFilteredData}>
                        Download Product Information
                    </Button>{' '}
                </Col>
                <Col>
                    {/*  Removing Download Demand button from GAVI view */}
                    {tenantSetting.tenant.fields.name !== 'Gavi-MST KM Platform' && (
                        <Button className='btn-primary bg-blue-300 m-b-start-03 m-b-end-03'>Download Demand</Button>
                    )}
                </Col>
            </Row>
        </>
    ),
};


  // Removing Create New button from GAVI view
  const toolbarButtons = () => {
    return (
      <>
        {tenantSetting.tenant.fields.name !== 'Gavi-MST KM Platform' && (
          <Button className='btn-primary-lg surface-brand-primary m-i-end-03' onClick={createNew}>Create New</Button>
        )}
      </>
    );
  };
  


  return (
    <div>
      {showEditProduct ?
        <ProductEditor 
          productId = { productId } 
          closePopup = {closePopup}
          show = {showEditProduct}
          apiKey =  {apiKey}
          disableProductEdits={disableProductEdits}
          tenantId={tenantId}
          visibleConfidentialities={visibleConfidentialities}
          editableConfidentialities={editableConfidentialities}
          showMSTFields={showMSTFields}
          userName={userName}
          appClass={appClass}
          getFromLocalStore={getFromLocalStore}
          toastClick={openLocalStoreProduct}
        />  
        : null  
      }
      <StatefulSearchTable
        data={products}
        rowsPerPage={20}
        metaData={metaData}
        sortData={sortOptions}
        apiKey={apiKey}
        onRowClick={update}
        module='product'
        editor={editor}
        settingsContent={settingsContent}
        settingsButtons={settingsContent.settingsButtons}
        toolbarButtons={toolbarButtons}
        appClass={appClass}
        onFilteredIDsChange={handleFilteredIDsChange} 
      />
    </div> 
  )
}

export default ProductSearch;
