import React, {useEffect, useState, useCallback} from 'react'
import { Button} from 'react-bootstrap'
import { useMsal } from '@azure/msal-react';

import ProgramEditor from './ProgramEditor'
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';
import StagingAndProdTransferButtons from '../../Alchemy/StagingAndProdTransferButtons';
import StatefulSearchTable from '../StatefulSearchTable';
import { useToast } from '../../ToastProvider';

const sortOptions = [
  {title:'Geography-Vaccine Group', field:'geography_vaccine_group'},
  {title:'Update Date', field:'update_date'}
]

// Title is the column title, displayField is what's shown in the table, filterField is the
// field used for filtering, isMulti enables or disables multi selection.
const metaData = [
  {title:'Geography', dispField:'geography_name', filterField:'geography_name',isMulti:true},
  {title:'Country', dispField:'country', filterField:'country', isMulti:true},
  {title:'Vaccine Group', dispField:'vaccine_group', filterField:'vaccine_group'},
  {title:'Routine/Campaign', dispField:'routine_campaign', filterField:'routine_campaign'},
  {title:'Gender', dispField:'gender', filterField:'gender'},
  {title:'Target Demographic', dispField:'target_demographic', filterField:'target_demographic'},
  {title:'Update Date', dispField:'update_date_str', filterField:'update_date_str'}
  
]

function DemandSearch({apiKey, appClass=null, editor=null, tenantEyesLevel=null}) {
  const [programs, setPrograms] = useState([]);

  // Product that gets edited when double clicked
  const [programId, setProgramId] = useState(null)
  // A toggle for showing the edit product modal
  const [showEditProgram, setShowEditProgram] = useState(false)

  // For dealing with database failures
  const [getFromLocalStore, setGetFromLocalStore] = useState(false);
  const { addToast } = useToast();

  //Get the email of the user
  const userName = useMsal().accounts[0].username;

  // fetch data
 const fetchData = useCallback(async () => {
  setPrograms([]); // Clear the data before fetching
  let result = await utils.getData('get_programs', apiKey);
  
  if (typeof result === 'string') {
    addToast({
      title: 'Demand error 3-h',
      body: result
    });
  } else {
    const augmentedResult = result.map((r) => ({
      ...r,
      update_date: new Date(r.update_date),
      update_date_str: new Date(r.update_date).toLocaleDateString(),
      routine_campaign: r.routine ? 'routine' : 'campaign',
      geography_vaccine_group: r.geography_name + r.vaccine_group
    }));
    setPrograms(augmentedResult);
  }
}, [apiKey, addToast]); 

// Fetch only when 'Demand' tab is active and editor is closed
useEffect(() => {
  if (editor !== 'Demand' || showEditProgram) return;
  fetchData();
}, [editor, showEditProgram, fetchData]); 

  


  function createNew(){
    setShowEditProgram(!showEditProgram)
    setProgramId(null)
    setGetFromLocalStore(false);
  }
  
  function toggleEditProgram(){
    setShowEditProgram(!showEditProgram)
  }

  function update(program){
    setShowEditProgram(!showEditProgram)
    setProgramId(program.program_id)
    setGetFromLocalStore(false)
  }

  function openLocalStoreProgram(){
    setShowEditProgram(true);
    setProgramId(null);
    setGetFromLocalStore(true);
  }

  const settingsContent = {
    settingsButtons: () => (
      <>
        <StagingAndProdTransferButtons
          userName={userName}
          apiKey={apiKey}
          staging_endpoint='transfer_to_staging'
          prod_endpoint='transfer_to_production'
          module='demand'
          disableRefresh={showEditProgram}
        />
      </>
    ),
  };

  const toolbarButtons = () => {
    return (
      <>
        <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
      </>
    )
  }

  return (
    <div>
      {showEditProgram ?
        <ProgramEditor programId = { programId } 
        closePopup = { toggleEditProgram}
        show = {showEditProgram}
        apiKey={apiKey}
        userName={userName}
        appClass={appClass}
        getFromLocalStore={getFromLocalStore}
        toastClick={openLocalStoreProgram}
        tenantEyesLevel={tenantEyesLevel}
        />
        : null  
      }
      <StatefulSearchTable
        data={programs}
        rowsPerPage={20}
        metaData={metaData}
        sortData={sortOptions}
        apiKey={apiKey}
        onRowClick={update}
        module='demand'
        editor={editor}
        settingsContent={settingsContent}
        //settingsButtons={settingsContent.settingsButtons}
        toolbarButtons={toolbarButtons}
        appClass={appClass}
      />
    </div>
    
  )
}

export default DemandSearch;