import React, {useEffect, useState, useCallback} from 'react';
import { Button } from 'react-bootstrap';
import {useToast} from '../../ToastProvider'

import OrganizationEditor from './OrganizationEditor'
import StatefulSearchTable from '../StatefulSearchTable';
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';

const metaData = [
  {title:'Organization Name', dispField:'org_name', filterField:'org_name'},
  {title:'Country', dispField:'country', filterField:'country'},
  {title:'Gavi Visible', dispField:'gavi_visible', filterField:'gavi_visible'},
  {title:'Licensure Experience', dispField:'licensure_experience'},
  {title:'PQ Experience', dispField:'pq_experience'}
]
const sortOptions=[
  {title:'Organization Name', field:'org_name'},
  {title:'Country', field:'country'}
]



function OrganizationSearch({apiKey, editor, appClass}) {
  const { addToast } = useToast(); 
  const [organizations, setOrganizations] = useState([])
  const [organization, setOrganization] = useState(null)
  const [showEditOrg, setShowEditOrg] = useState(false)



  const fetchData = useCallback(async () => {
    setOrganizations([]); 
  
    let result = await utils.getData("get_organizations", apiKey);
  
    if (typeof result === 'string') {
      addToast({
        title: 'Failed to load organization data.  Error code 7-a',
        body: result
      });
      return;
    }
  
    let augmentedResult = result.map((r) => ({
      ...r,
      country: r.country ? r.country.country : '' // Extracts country safely
    }));
  
    setOrganizations(augmentedResult);
  }, [apiKey, addToast]);  
  

  // fetch data only when the active editor is Organizations and the edit modal is not open
  useEffect(() => {
    if (editor !== 'Organizations' || showEditOrg) return; 
    fetchData();  
  }, [editor, showEditOrg, fetchData]);  

  function toggleShowEditOrg(){
    setShowEditOrg(!showEditOrg)
  };

  function createNew(){
    toggleShowEditOrg()
    setOrganization(null)
  }
  
  function update(org){
    toggleShowEditOrg()
    setOrganization(org)
  }

  const toolbarButtons = () => {
    return ( 
    <>
      <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
    </>
    )
  }


  return (
    <div>
      {showEditOrg ?
        <OrganizationEditor 
          id = { organization!==null?organization.org_id:null}
          closePopup = { toggleShowEditOrg }
          apiKey ={apiKey}
          show = {showEditOrg}
          onSubmit = {() => setShowEditOrg(false)}
          appClass={appClass}
        />  
        : null  
      }
      <StatefulSearchTable
        toolbarButtons={toolbarButtons}
        data={organizations}
        metaData={metaData}
        apiKey={apiKey}
        onRowClick={update}
        sortData={sortOptions}
        module='organization'
        editor={editor}
        appClass={appClass}
      />
    </div>
    
  )
}

export default OrganizationSearch;