import React from 'react';
import getCountryISO2 from "country-iso-3-to-2";


function CountryCard({ country, index, onClick}) {

    let currYear = new Date().getFullYear();

    return (
        <div className="country-card boxshadow-depth-4 m-b-end-06 m-i-start-04 m-i-end-04" key={index} onClick={onClick}>
            <div className="card-img">
                <img src={`https://flagcdn.com/${getCountryISO2(country.ISO).toLowerCase()}.svg`} alt={`${country.country} flag`} />
            </div>
            <div className='p-i-06 p-b-end-06 p-b-start-06'>
                <div className='display-flex justify-between'>
                    <h2 className="h2 m-b-start-05 m-b-end-04 country-title">{country.country}</h2>
                    <span className="region-badge label-small m-b-start-05 m-b-end-05">{country.who_region}</span>
                </div>
                <div className='data-row m-b-start-03 m-b-end-03'>
                    <p className='m-b-end label-small'>Population</p>
                    <p className='m-b-end body-medium-comfortable'>{country.total_population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                </div>
                <div className='data-row m-b-start-03 m-b-end-03'>
                    <p className='m-b-end label-small'>{`No. of Campaigns in ${currYear}`}</p>
                    <p className='m-b-end body-medium-comfortable'>{country.campaign_count}</p>
                </div>
            </div>
      </div>
    );
}

export default CountryCard;