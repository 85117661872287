import React from "react";
import CheckBoxDropDownSelector from "../MktActionsFilterBarComps/CheckBoxDropDownSelector";

function YearFilterMultiple({ yearList, filterChangeHandler, curFilters }) {

    // Extract the currently selected years as an array of values
    const curYearFilterItems = curFilters
        .filter((item) => item.field === 'year')
        .flatMap((item) => item.value);

    // Convert yearList into object for CheckBoxDropDownSelector
    const yearsObj = yearList.reduce((obj, year) => {
        obj[year.label] = curYearFilterItems.includes(year.label); 
        return obj;
    }, {});


    const handleYearSelection = (event) => {
        const selectedYear = event.target.value;

        // Toggle the selected year in the current selection
        const updatedYears = curYearFilterItems.includes(selectedYear)
            ? curYearFilterItems.filter((y) => y !== selectedYear) // Remove the year
            : [...curYearFilterItems, selectedYear]; // Add the year

        filterChangeHandler({
            field: 'year',
            value: updatedYears.length > 0 ? updatedYears : null,
        });
    };


    const yearLabelString =
         curYearFilterItems.length > 0
                ? curYearFilterItems.length <= 3
                    ? curYearFilterItems.join(', ') 
                    : `${curYearFilterItems.length} Years Selected` 
                : 'Year Published';


    return (
        <CheckBoxDropDownSelector
            keepOpen={true}
            options={yearsObj}
            selected={curYearFilterItems}
            displayOptionHandler={handleYearSelection}
            labelString={yearLabelString}
            selectAll={false} 
            className="toolbar-standard-dropdown"
            name="year"
        />
    );
}

export default YearFilterMultiple;
