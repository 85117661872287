import React, {useMemo} from 'react';
import FieldDisplay from '../FieldDisplay';
import utils from '../../utils';

function CountryProfileFieldWrapper({ tenant,showGenPageTitle, cntryInfo, fields, fieldsDescr, ISO }) {

    //this component replaces items in the navDescr with the content from the record

    

    const insertCountryTokens = (flds, fldDescr, countryInfo) => {

        const countryTokenData = {
            'curYear': new Date().getFullYear(),
            'curYear-1': new Date().getFullYear() - 1,
            'curYear+1': new Date().getFullYear() + 1,
            'lastCurNextYear': [new Date().getFullYear() - 1, new Date().getFullYear() + 1],
            'Country': countryInfo.country,
            'countryName': countryInfo.country,
            'country_name': countryInfo.country,
        }

        if(fldDescr && fldDescr.length > 0) {
            if(fldDescr[0].hasOwnProperty('subNav') && fldDescr[0].subNav.length > 0) {
                //we need to iterate through the subNav items and replace {{fieldName}} and **fieldValue** with the fieldName from the record
                // not ideal as it mismatches the other replacement structure of [[item]] but to prevent full meltdown, we will do it this way
                fldDescr[0].subNav.forEach((navItem) => {
                    if(navItem.hasOwnProperty('pageItems')) {
                        navItem.pageItems.forEach((pageItem) => {
                            if(pageItem.hasOwnProperty('itemTitle')) {
                                pageItem.itemTitle = utils.replaceTokensInField(pageItem.itemTitle, countryTokenData);
                            }
                            if(pageItem.hasOwnProperty('pBI_Filter') && pageItem.pBI_Filter.length > 0) {
                                pageItem.pBI_Filter.forEach((filterItem) => {
                                    if(filterItem.fields && filterItem.fields.filterValues) {
                                        filterItem.fields.filterValues = utils.replaceTokensInFilter(filterItem.fields.filterValues, countryTokenData);
                                        //we have to actually look up the country targetColumn because it gets replaced with the country name and no tokenizing after first go
                                        if (filterItem.fields.targetColumn.toLowerCase() === 'country') {
                                            filterItem.fields.filterValues = countryTokenData.Country;
                                        } 
                                    }
                                });
                            }
                        });
                    }
                });
            }
        }
    
        if(flds.fields) {
            Object.keys(flds.fields).forEach((key) => {
                if(flds.fields[key]) {
                    flds.fields[key] = utils.replaceTokensInField(flds.fields[key], countryTokenData);
                }
            });
        }

        return { fields: flds, fieldsDescr: fldDescr };
    }

    

    let tokenizedData = useMemo(() => {
        return insertCountryTokens(fields, fieldsDescr, cntryInfo);
    
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [ISO]);

    return (
        <FieldDisplay
            tenant={tenant}
            showGenPageTitle={showGenPageTitle}
            fields={tokenizedData.fields}
            fieldsDescr={tokenizedData.fieldsDescr} 
            isForCountry={cntryInfo} />
    )
}

export default CountryProfileFieldWrapper