import React from "react";
import FormattedArticleList from "../News/FormattedArticleList";

function CENewsToElasticAdapter({ articles, formatForNewsList, filterChanged, handleBadgeClick=null}) {

    const formattedArticles = formatForNewsList(articles);

    return (
        <FormattedArticleList
            list={formattedArticles}
            flatList={true}
            engineName="ce-articles"
            handleBadgeClick={handleBadgeClick}
            showTags={false} 
            showBadges={true} 
            useStandardNewsLink={false} 
            filterChanged={filterChanged}
        />
    );
}


export default CENewsToElasticAdapter;