import React, { useEffect, useState } from 'react';
import { Button} from 'react-bootstrap'

import StatefulSearchTable from '../StatefulSearchTable';
import PriceEditor from './PriceEditor';
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';
import PriceImporter from './PriceImporter';
import PriceProjectionImporter from './PriceProjectionImporter'
import TransferButton from '../TransferButton';


const metaData=[
  {title:'Vaccine Subtype', dispField:'vaccine_subtype', filterField:'vaccine_subtype'},
  {title:'Company', dispField:'company', filterField:'company'},
  {title:'Product Name', dispField:'product_name', filterField:'product_name'},
  {title:'Target Demographic', dispField:'target_demographic', filterField:'target_demographic'},
  {title:'Form', dispField:'form', filterField:'form'},
  {title:'Container', dispField:'container', filterField:'container'},
  {title:'Vial Size', dispField:'vial_size', filterField:'vial_size', asNumber:true},
  {title:'Market', dispField:'market_segment', filterField:'market_segment'},
  {title:'Distributor', dispField:'distributor',filterField:'distributor'},
  {title:'Source', dispField:'source', filterField:'source'},
  {title:'Update Date', dispField:'update_date_str'}
]

const sortData=[
  {title:"Standard", field:'standard_sort'},
  {title:"Date", field:'update_date'}

]

const fetchData = async(setPrices, apiKey)=> {
  const result = await utils.getData("get_prices", apiKey)
  const augmentedResult = result.map((r)=>{
    return {
      ...r,
      vaccine:r.product_characteristic.vaccine,
      vaccine_subtype:r.product_characteristic.vaccine_subtype,
      product_name:r.product_characteristic.product_name,
      company:r.product_characteristic.manufacturer,
      form:r.product_characteristic.form,
      container:r.product_characteristic.container_type,
      vial_size:r.product_characteristic.container_quantity,
      distributor:r.distributor?r.distributor.org_name:null,
      update_date:new Date(r.update_date),
      update_date_str:(new Date(r.update_date)).toLocaleDateString(),
      standard_sort:r.product_characteristic.vaccine_subtype
        +(r.product_characteristic.manufacturer===null?'_':r.product_characteristic.manufacturer)
        +(r.product_characteristic.form===null?'_':r.product_characteristic.form)
        +(r.product_characteristic.container_type===null?'_':r.product_characteristic.container_type)
        +(r.product_characteristic.container_quantity===null?'_':r.product_characteristic.container_quantity)
        +(r.market_segment)
        +(r.distributor===null?'_':r.distributor)
    }
  })
  if (result !== null) {
    setPrices(augmentedResult);
  }
}



function PriceSearch({apiKey, disableProductEdits, showMSTFields, tenantId, visibleConfidentialities, editableConfidentialities, userName, editor, appClass=null}) {
  const [xferMessage, setXferMessage] = useState()
  const [errors, setErrors] = useState([])
  const [extraContent, setExtraContent] = useState()
  // Local representation with data augmentations
  const [prices, setPrices] = useState([]);

  // Price that gets edited when double clicked
  const [productDetailId, setProductDetailId] = useState(null)

  // A toggle for showing the edit product modal
  const [showEditPrice, setShowEditPrice] = useState(false)

  // only fetch when Price tab is active and editor is closed
  useEffect(() => {
    if (editor !== 'Price' || showEditPrice) return; 
    fetchData(setPrices, apiKey);
  }, [editor, showEditPrice, apiKey]);
  

  const createNew = () => {
    setShowEditPrice(!showEditPrice)
    setProductDetailId(null)
  }

  const update = (productDetail) => {
    setShowEditPrice(!showEditPrice)
    setProductDetailId(productDetail.product_detail_id)
  }

  // Settings Content 
const settingsContent = {
   triggerXfer: async (endpoint) => {
    setXferMessage("Working on transfer")
    const result = await utils.db_api_post(endpoint, apiKey,'')
    setXferMessage(result === true ? "Done" : "Error");
  },

  settingsButtons: () => (
    <>
      <TransferButton 
        userName={userName}
        apiKey={apiKey}
        disabled={false}
        endpoint={'transfer_price_to_production'}
        module={'price'}
        environment='production'/>
      <PriceImporter apiKey={apiKey} 
        refreshData={()=>fetchData(setPrices, apiKey)} 
        setExtraContent={setExtraContent} 
        setXferMessage={setXferMessage} 
        setErrors={setErrors}/>{' '}
      <PriceProjectionImporter apiKey={apiKey} 
        setXferMessage={setXferMessage} 
        setErrors={setErrors}/>
      {extraContent?extraContent:null}
      <div>{xferMessage}</div>
      <div>
        {errors.length > 0 ?
          <div>
            <div className="error">Errors:</div>
            {
              typeof errors === 'string' ? 
                <li className='error'>{errors}</li>
              :
                errors.map((e, i) => <li key ={i} className="error">{e}</li>)
            }
          </div>
          : null
        }
      </div>
    </>
  )
}

  const toolbarButtons = () => {
    return ( <>
      <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
    </>
    )
  }


  return (
    <div>
      {showEditPrice ?
        <PriceEditor
          productDetailId={productDetailId}
          apiKey={apiKey}
          closePopup={()=>setShowEditPrice(!showEditPrice)}
          disableProductEdits={disableProductEdits}
          tenantId={tenantId}
          visibleConfidentialities={visibleConfidentialities}
          editableConfidentialities={editableConfidentialities}
          showMSTFields={showMSTFields}
          userName={userName}
          appClass={appClass}
        />
        : null
      }
      <StatefulSearchTable
        data={prices}
        metaData={metaData}
        sortData={sortData}
        onRowClick={update}
        apiKey={apiKey}
        module='price'
        editor={editor} // 'Price'
        rowsPerPage={20}
        settingsContent={settingsContent}
        settingsButtons={settingsContent.settingsButtons}
        toolbarButtons={toolbarButtons}
        appClass={appClass}
      />
    </div>
  )
}

export default PriceSearch;